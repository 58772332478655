import React, { useState } from 'react'
import BannerImage from "../../../../Assets/BannerImage.png"
import { Col, Row } from 'react-bootstrap'
import "./index.css"
import { NavLink } from 'react-router-dom'
const HomeBanner = () => {


    const [readMore, setReadMore] = useState(true)
    const scrollToSection = () => {
        // const section = document.getElementById("homeServices");
        // if (section) {
        //   section.scrollIntoView({ behavior: "smooth", block: "start" });
        // }
        const section = document.getElementById("homeServices");
        if (section) {
            const targetPosition = section.offsetTop; // Section's top position relative to the document
            const startPosition = window.pageYOffset; // Current scroll position
            const distance = targetPosition - startPosition; // Distance to scroll
            const duration = 1000; // Total duration in milliseconds
            let startTime = null;

            const animation = (currentTime) => {
                if (startTime === null) startTime = currentTime;
                const timeElapsed = currentTime - startTime;

                // Ease-in-out formula for smoother scroll
                const easeInOut = (t, b, c, d) => {
                    t /= d / 2;
                    if (t < 1) return (c / 2) * t * t + b;
                    t--;
                    return (-c / 2) * (t * (t - 2) - 1) + b;
                };

                const run = easeInOut(timeElapsed, startPosition, distance, duration);
                window.scrollTo(0, run);

                if (timeElapsed < duration) requestAnimationFrame(animation);
            };

            requestAnimationFrame(animation);
        }
    };
    return (
        <section className="homeBanerMainSection elementor-section elementor-top-section elementor-element elementor-element-4295be78 elementor-section-full_width elementor-section-height-min-height elementor-section-items-stretch elementor-section-height-default sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" >
            <div className="BannerText elementor-container elementor-column-gap-extended">

                <div className="mobilebanner elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-6a261af6 sc_content_align_inherit sc_layouts_column_icons_position_left animation_type_block sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited animated eventicity-fadeinup" >
                    <div className="elementor-widget-wrap elementor-element-populated">
                        <div className="elementor-element elementor-element-2b796d30 sc_fly_static elementor-widget elementor-widget-image trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" >
                            <div className="elementor-widget-container">
                                <img height={"100%"} width={"100%"}  src={BannerImage}  className='pointerevent'/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="completeBanner elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-65a4fd55 sc_content_align_inherit sc_layouts_column_icons_position_left animation_type_block sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited animated eventicity-fadeinup" >
                    <div className="elementor-widget-wrap elementor-element-populated">
                        <div className="elementor-element elementor-element-777a51c elementor-widget__width-initial sc_fly_static elementor-widget elementor-widget-trx_sc_title trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" >
                            <div className="elementor-widget-container">
                                <div className="sc_title sc_title_default">
                                    <Row className='bannerTitle'>
                                        <Col md="12"><span className="bannerLineTopText sc_item_subtitle sc_title_subtitle sc_item_subtitle_above sc_item_title_style_default "></span></Col>
                                        <Col md="12">
                                        {/* <h1 className="bannerLineText  sc_item_title sc_item_title2 sc_item_title_style_default sc_item_title_tag  "> */}
                                        <h1 className="bannerLineTopText_2 sc_item_title sc_item_title2 sc_item_title_style_default sc_item_title_tag">
                                            <span className="sc_item_title_text2">
                                                <span className="readMoreText">
                                                    Family Anchor RJ Meeit, the warm, energetic, and trustworthy voice that families worldwide have come to know and love. With his engaging presence and deep appreciation for family values, Family Anchor RJ Meeit, has earned a special place in the hearts of listeners of all ages, becoming a true anchor for families everywhere.
                                                    {/* Family Anchor RJ Meeit, the friendly energetic, and heartwarming voice that families across Surat have come to trust and love.&nbsp;With his engaging personality and deep understanding of family values, RJ Meeit has carved a special place in the hearts of listeners as a true anchor for all ages. */}
                                                </span>
                                            </span></h1>
                                            <NavLink to={"/RJmeeit/About"} className="sc_button sc_button_default sc_button_size_normal sc_button_icon_left"><span className="sc_button_text"><span className="sc_button_title">Read More</span></span></NavLink>
                                            <div className="mobilescroll elementor-widget-wrap elementor-element-populated">
                                                <div className="elementor-element elementor-element-1cf35d8 sc_fly_static elementor-widget elementor-widget-spacer trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="1cf35d8" data-element_type="widget" data-widget_type="spacer.default">
                                                    <div className="elementor-widget-container">
                                                        <div className="elementor-spacer">
                                                            <div className="elementor-spacer-inner" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="elementor-element elementor-element-37b7957 sc_fly_static elementor-widget elementor-widget-trx_sc_button trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="37b7957" data-element_type="widget" data-widget_type="trx_sc_button.default">
                                                    <div className="elementor-widget-container">
                                                        <div className="sc_item_button sc_button_wrap">
                                                            <a onClick={scrollToSection} className="sc_button sc_button_simple sc_button_size_normal color_style_dark">
                                                                <svg style={{ marginRight: "15px" }} xmlns="http://www.w3.org/2000/svg" width={"12px"} viewBox="0 0 12.098 17.58"><path id="Path_6098" data-name="Path 6098" d="M17.341,47.387l-5.232-5.232a.817.817,0,1,0-1.156,1.156l3.836,3.836H.817a.817.817,0,0,0,0,1.635H14.789l-3.836,3.836a.817.817,0,0,0,1.156,1.156l5.231-5.232A.817.817,0,0,0,17.341,47.387Z" transform="translate(54.014) rotate(90)" fill="#fcfcfc"></path></svg>
                                                                <span className="sc_button_text">
                                                                    <span className="sc_button_title">Scroll Down</span>
                                                                </span>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                        </Col>
                                        {/* <Col md="12"><h1 className="bannerLineText sc_item_title sc_title_title sc_item_title_style_default sc_item_title_tag"><span className="sc_item_title_text2">and heartwarming voice that families across Surat have come to trust and love.</span></h1></Col>
                                        <Col md="12"><h1 className="bannerLineText sc_item_title sc_title_title sc_item_title_style_default sc_item_title_tag"><span className="sc_item_title_text2">With his engaging personality and deep understanding of family values,</span></h1></Col>
                                        <Col md="12"><h1 className="bannerLineText sc_item_title sc_title_title sc_item_title_style_default sc_item_title_tag"><span className="sc_item_title_text2">Meeit has carved a special place in tha hearts of listeners as a true anchor for all age. </span></h1></Col> */}
                                    </Row>
                                </div></div>
                        </div>
                        {/* <section className="elementor-section elementor-inner-section elementor-element elementor-element-3e0ffd88 elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" >
                            <div className="elementor-container elementor-column-gap-no">
                                <div className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-1b8b3008 sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" >
                                    <div className="readMoreText elementor-widget-wrap elementor-element-populated">
                                        <div className="elementor-element elementor-element-424b3520 elementor-widget__width-initial sc_fly_static elementor-widget elementor-widget-trx_sc_title trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" >
                                            <div className="elementor-widget-container">
                                                <div className="sc_title sc_title_default">
                                                    <div className="sc_item_button sc_button_wrap sc_item_button sc_item_button_default sc_item_button_size_normal sc_title_button"><a href="#" className="sc_button sc_button_default sc_button_size_normal sc_button_icon_left"><span className="sc_button_text">
                                                        <span className="sc_button_title">Read More</span></span></a></div></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section> */}
                    </div>
                </div>
                <div className="desktopbanner elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-6a261af6 sc_content_align_inherit sc_layouts_column_icons_position_left animation_type_block sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited animated eventicity-fadeinup" >
                    <div className="elementor-widget-wrap elementor-element-populated">
                        <div className="elementor-element elementor-element-2b796d30 sc_fly_static elementor-widget elementor-widget-image trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" >
                            <div className="elementor-widget-container">
                                <img height={"100%"} width={"100%"} src={BannerImage}  className='pointerevent' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default HomeBanner
