import React from 'react'
import {ScaleLoader } from "react-spinners"

const Loader = () => {

  return (
    <div style={{ position:"fixed", zIndex: "10000000", left: "50%", top: "50%" }}>
  <ScaleLoader   height={45}
  margin={2}
  radius={30}
  width={7} color='#F25A2A' />
  </div>
  )
}
export default Loader