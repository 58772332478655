import React from 'react'
import "./index.css"
const Energetic2 = () => {
  return (
    <section className="elementor-section elementor-top-section elementor-element elementor-element-0f86348 elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="0f86348" data-element_type="section">
    <div className="elementor-container elementor-column-gap-extended">
      <div className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-bf5a34c sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="bf5a34c" data-element_type="column">
        <div className="elementor-widget-wrap elementor-element-populated">
          <section className="elementor-section elementor-inner-section elementor-element elementor-element-591f1fd elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="591f1fd" data-element_type="section">
            <div className="elementor-container elementor-column-gap-no">
              <div className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-113fb9e sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="113fb9e" data-element_type="column">
                <div className="elementor-widget-wrap elementor-element-populated">
                  <div data-animation-type="block" className="elementor-element elementor-element-e2dc595 no-title animation_type_block sc_fly_static elementor-widget elementor-widget-image trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited animated eventicity-fadeinup" data-id="e2dc595" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;eventicity-fadeinup&quot;}" data-widget_type="image.default">
                    <div className="elementor-widget-container ">
                      <img decoding="async" src={require("../../../Assets/Energetic2.jpg")} className='pointerevent' title="img-17-copyright" alt="img-17-copyright" loading="lazy" />													</div>
                  </div>
                  {/* <div data-animation-type="block" className="elementor-element elementor-element-cc0b347 elementor-widget__width-auto elementor-absolute elementor-widget-mobile__width-initial elementor-widget-tablet__width-initial animation_type_block sc_fly_static elementor-widget elementor-widget-image trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited animated eventicity-infinite-rotate" data-id="cc0b347" data-element_type="widget" data-settings="{&quot;_position&quot;:&quot;absolute&quot;,&quot;_animation&quot;:&quot;eventicity-infinite-rotate&quot;}" data-widget_type="image.default">
                    <div className="elementor-widget-container">
                      <img loading="lazy" decoding="async" width={148} height={148} src="https://eventicity.themerex.net/wp-content/uploads/2024/03/anim-text.svg" className="attachment-full size-full wp-image-52732" alt />													</div>
                  </div> */}
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <div className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-3a13b62 sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="3a13b62" data-element_type="column">
        <div className="elementor-widget-wrap elementor-element-populated">
          <div data-animation-type="block" className="elementor-element elementor-element-019cbbe animation_type_block sc_fly_static elementor-widget elementor-widget-trx_sc_title trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited animated eventicity-fadeinup" data-id="019cbbe" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;eventicity-fadeinup&quot;}" data-widget_type="trx_sc_title.default">
            <div className="elementor-widget-container">
              <div className="sc_title sc_title_default energeticself"><h1 className="sc_item_title sc_title_title sc_item_title_style_default sc_item_title_tag">
                <span className="sc_item_title_text">Energetic</span></h1><div className="sc_item_descr sc_title_descr">
                    <p style={{textAlign:"justify"}}>There’s nothing more detrimental to the success of an event than a disengaged or lifeless crowd.
                    RJ Meeit possesses an inherently vibrant and dynamic persona that effortlessly captivates, involves, and energizes every individual in the audience. His ability to connect with people on a personal level ensures that every attendee, irrespective of their age, background, or status, feels equally engaged and excited. This unique quality not only uplifts the atmosphere but also leaves a lasting impression, transforming any event into a memorable and lively experience.</p>
              </div></div>		</div>
          </div>
          <div className="elementor-element elementor-element-d85589c sc_fly_static elementor-widget elementor-widget-spacer trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="d85589c" data-element_type="widget" data-widget_type="spacer.default">
            <div className="elementor-widget-container">
              <div className="elementor-spacer">
                <div className="elementor-spacer-inner" />
              </div>
            </div>
          </div>
          <div data-animation-type="block" className="elementor-element elementor-element-c6fd4a3 no-title animation_type_block sc_fly_static elementor-widget elementor-widget-image trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited animated eventicity-fadeinup" data-id="c6fd4a3" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;eventicity-fadeinup&quot;}" data-widget_type="image.default">
            <div className="elementor-widget-container">
              <img decoding="async" src={require("../../../Assets/Energetic1.jpg")} className='pointerevent' title="img-18-copyright" alt="img-18-copyright" loading="lazy" />													</div>
          </div>
        </div>
      </div>
    </div>
  </section>
  )
}

export default Energetic2
