import React from 'react'
import Marquee from "react-fast-marquee";
import "./index.css"
const HomeLiveScroll = () => {
    return (
        <section className="trx_addons_has_bg_text elementor-section elementor-top-section elementor-element elementor-element-af0cc35 elementor-section-full_width elementor-section-height-min-height elementor-section-content-middle elementor-section-height-default elementor-section-items-middle sc_fly_static trx_addons_has_bg_text_inited trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" >
            <div className="elementor-element elementor-element-ae932e9 sc_height_huge sc_fly_static elementor-widget elementor-widget-spacer trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited m-0" style={{ marginBottom: '0px' }} >
                <div className="elementor-widget-container">
                    <div className="elementor-spacer">
                        <div className="elementor-spacer-inner" />
                    </div>
                </div>
            </div>
            <Marquee >
                <span className="trx_addons_bg_text_item trx_addons_bg_text_char"> &nbsp;FAMILY&nbsp; </span>
                <span className="trx_addons_bg_text_item trx_addons_bg_text_char">&nbsp;ANCHOR&nbsp;</span>
                <span className="trx_addons_bg_text_item trx_addons_bg_text_char">&nbsp;RJ MEEIT&nbsp;</span>
                <span className="trx_addons_bg_text_item trx_addons_bg_text_char">&nbsp;WEDDING&nbsp;</span>
                <span className="trx_addons_bg_text_item trx_addons_bg_text_char">&nbsp;CORPORATE&nbsp;</span>
                <span className="trx_addons_bg_text_item trx_addons_bg_text_char">&nbsp;GOVERNMENT&nbsp;</span>
                <span className="trx_addons_bg_text_item trx_addons_bg_text_char">&nbsp;PUBLIC&nbsp;</span>
                <span className="trx_addons_bg_text_item trx_addons_bg_text_char">&nbsp;SOCIAL&nbsp;</span>
                <span className="trx_addons_bg_text_item trx_addons_bg_text_char">&nbsp;EVENTS&nbsp;</span>
                <span className="trx_addons_bg_text_item trx_addons_bg_text_char">&nbsp;VOICE OVER&nbsp;</span>
                <span className="trx_addons_bg_text_item trx_addons_bg_text_char">&nbsp;TRAINER&nbsp;</span>
                <span className="trx_addons_bg_text_item trx_addons_bg_text_char">&nbsp;GAME&nbsp;</span>
                <span className="trx_addons_bg_text_item trx_addons_bg_text_char">&nbsp;JOCKEY&nbsp;</span>
            </Marquee>
        </section>
    )
}

export default HomeLiveScroll
