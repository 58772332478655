import React from 'react'

const BookEnquiryHeader = () => {
  return (
<section  className="elementor-section elementor-top-section elementor-element elementor-element-40e356 elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"  >
  <div className="elementor-container elementor-column-gap-extended">
    <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-34d859ee sc_content_align_center sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"  >
      <div className="elementor-widget-wrap elementor-element-populated">
        <div className="sc_layouts_item elementor-element elementor-element-61c32b56 sc_fly_static elementor-widget elementor-widget-spacer trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"  >
          <div className="elementor-widget-container">
            <div className="elementor-spacer">
              <div className="elementor-spacer-inner" />
            </div>
          </div>
        </div>
        <div className="sc_layouts_item elementor-element elementor-element-7e40abca sc_fly_static elementor-widget elementor-widget-trx_sc_layouts_title trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"  >
          <div className="elementor-widget-container">
            <div className="sc_layouts_title sc_align_center with_content without_image without_tint"><div className="sc_layouts_title_content"><div className="sc_layouts_title_title"><h1 className="sc_layouts_title_caption">Booking Inquiry</h1></div></div></div>		</div>
        </div>
        <div className="sc_layouts_item elementor-element elementor-element-2c7db030 sc_fly_static elementor-widget elementor-widget-spacer trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"  >
          <div className="elementor-widget-container">
            <div className="elementor-spacer">
              <div className="elementor-spacer-inner" />
            </div>
          </div>
        </div>
        <div className="sc_layouts_item elementor-element elementor-element-6735ad1d elementor-view-default elementor-position-top elementor-mobile-position-top sc_fly_static elementor-widget elementor-widget-icon-box trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"  >
          <div className="elementor-widget-container">
            <div className="elementor-icon-box-wrapper">
              <div className="elementor-icon-box-icon">
                <span className="elementor-icon elementor-animation-">
                  <svg xmlns="http://www.w3.org/2000/svg" width="19.597" height="10.93" viewBox="0 0 19.597 10.93"><g transform="translate(2.799 -113.076)"><path d="M34.277,22.13l-9.564-9.564a.8.8,0,1,1,1.131-1.131l8.433,8.433,8.433-8.433a.8.8,0,1,1,1.131,1.131Z" transform="translate(-27.278 101.876)" /></g></svg>				</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
  )
}

export default BookEnquiryHeader
