
import { toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

export const notifySuccess = (message) =>
    toast(<p style={{ fontSize: 16,display:"flex",justifyContent:"center",margin:"auto",fontFamily:"Poppins" }}>{message}</p>, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      newestOnTop: false,
      closeOnClick: true,
      rtl: false,
      pauseOnFocusLoss: true,
      draggable: true,
      pauseOnHover: true,
      type: "success"
    });
  

    export const notifyError = (message) =>
        toast(<p style={{ fontSize: 16,display:"flex",justifyContent:"center",margin:"auto" ,fontFamily:"Poppins"}}>{message}</p>, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          newestOnTop: false,
          closeOnClick: true,
          rtl: false,
          pauseOnFocusLoss: true,
          draggable: true,
          pauseOnHover: true,
          type: "error"
        });

        export const handleKeyDownNumber = (event) => {
            const charCode = event.which || event.keyCode;
            if (event.key === "v" && (event.ctrlKey || event.metaKey)) {
              return; // Allow pasting
            }
            if (event.key === "c" && (event.ctrlKey || event.metaKey)) {
              return; // Allow copying
            }
            if (
              !/\d/.test(event.key) &&
              event.key !== "Backspace" &&
              event.key !== "Delete" &&
              charCode !== 9
            ) {
              event.preventDefault();
            }
          };
        
          export const handlePaste = (event) => {
            const pastedText = event.clipboardData.getData("text/plain");
          
            const isValid = /^\d+$/.test(pastedText.trim());
            if (!isValid) {
              event.preventDefault();
            }
          };