import React from 'react'
import "./index.css"
import 'swiper/css';
import 'swiper/css';
import 'swiper/css/pagination';
import HomeBanner from './Component/HomeBanner';
import HomeService from './Component/HomeService';
import HomeAbout from './Component/HomeAbout';
import LogoLivescroll from './Component/LogoLivescroll';
import HomeVideo from './Component/HomeVideo';
import HomeTeam from './Component/HomeTeam';
import HomeLiveScroll from './Component/HomeLiveScroll';
import HomeBookInquiry from './Component/HomeBookInquiry';
import HomeGallery from './Component/HomeGallery';
import HomeLayout from '../HomeLayout';

const HomePage = () => {
    return (
        <HomeLayout>
            <HomeBanner /> 
            <HomeService />
            <HomeAbout />
            <LogoLivescroll />
            {/* <HomeVideo /> */}
            <HomeTeam />
            <HomeLiveScroll />
            <HomeBookInquiry />
            <HomeGallery />
        </HomeLayout>
    )
}

export default HomePage
