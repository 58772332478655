import React from 'react'

const HomeLayout = ({children}) => {
    return (
        <div className="page_content_wrap" >
            <div className="content_wrap_fullscreen">
                <div className="content">
                    <a id="content_skip_link_anchor" className="eventicity_skip_link_anchor" href="#" />
                    <article id="post-52477" className="post_item_single post_type_page post-52477 page type-page status-publish hentry">
                        <div className="post_content entry-content">
                            <div className="elementor elementor-52477">
                                {children}
                            </div>
                        </div>
                    </article>
                </div>
            </div>
        </div>
    )
}

export default HomeLayout
