import React from 'react'
import "./index.css"
import { NavLink } from 'react-router-dom'
const ErrorPage = () => {
    return (
        <div className="page_content_wrap">
            <div className="content_wrap">
                <div className="content12">
                    <a id="content_skip_link_anchor" className="eventicity_skip_link_anchor" href="#" />
                    <article className="post_item_single post_item_404 post-0 unknown type-unknown status-publish hentry">
                        <div className="post_content">
                            <h1 className="page_title">
                                <span> 4 </span>
                                <img src={require("../../Assets/CoffeeNP.png")} alt={404} className='pointerevent' />
                                <span> 4 </span>
                            </h1>
                            <div className="page_info">
                                <h3 className="page_subtitle">Oops...</h3>
                                <p className="page_description">We're sorry, but something went wrong.</p>
                                <NavLink to={"/RJmeeit/Home"} className="go_home theme_button">View Homepage</NavLink>
                            </div>
                        </div>
                    </article>						</div>
            </div>
        </div>

    )
}

export default ErrorPage
