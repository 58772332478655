import React from 'react'
import "./index.css"
import { NavLink } from 'react-router-dom'
import MapComponent from '../MapComponent'

const Footer = () => {
    const latitude = 21.147045006755413
    const longitude = 72.76639171586062
    const Address = "804 SNS ARISTA, OPP. HAPPY RESIDENCY, BH. PRIME SHOPPERS, VESU SURAT 395007"
    return (
        <footer className="footer_wrap_self footer_wrap footer_custom footer_custom_4105 footer_custom_footer-default scheme_dark">
            <div className="elementor elementor-4105">

                <section className="elementor_section_self elementor-section elementor-top-section elementor-element elementor-element-2e861df sc_layouts_row sc_layouts_row_type_compact scheme_orange_dark elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"  >
                    <div className="elementor-container-self elementor-container elementor-column-gap-extended">
                        <div className='footerCol1'>
                            <div className="elementor-widget-wrap elementor-element-populated">
                                <div className="sc_layouts_item elementor-element elementor-element-7fac3c9 sc_fly_static elementor-widget elementor-widget-trx_sc_title trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"   >
                                    <div className="elementor-widget-container">
                                        <div className="sc_title sc_title_default"><h6 className="sc_item_title sc_title_title sc_item_title_style_default sc_item_title_tag"><span className="sc_item_title_text"></span></h6></div>		</div>
                                </div>
                                <div className="sc_layouts_item elementor-element elementor-element-c0a23d4 sc_fly_static elementor-widget elementor-widget-spacer trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"   >
                                    <div className="elementor-widget-container">
                                        <div className="elementor-spacer">
                                            <div className="elementor-spacer-inner" />
                                        </div>
                                    </div>
                                </div>
                                <div className="map_parent_div sc_layouts_item elementor-element elementor-element-2b024b2 sc_fly_static elementor-widget elementor-widget-text-editor trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited">
                                    <MapComponent className="mobilemap" height={"250px"} latitude={parseFloat(latitude)} longitude={parseFloat(longitude)} Address={Address} />
                                </div>

                            </div>
                        </div>
                        <div className='footerCol2'>
                            <div className="elementor-widget-wrap elementor-element-populated">
                                <div className="sc_layouts_item elementor-element elementor-element-6c5f7f2 sc_fly_static elementor-widget elementor-widget-trx_sc_title trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"   >
                                    <div className="elementor-widget-container">
                                        <div className="sc_title sc_title_default"><h6 className="sc_item_title sc_title_title sc_item_title_style_default sc_item_title_tag"><span className="sc_item_title_text">Office</span></h6></div>		</div>
                                </div>
                                <div className="sc_layouts_item elementor-element elementor-element-73713f9 sc_fly_static elementor-widget elementor-widget-spacer trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"   >
                                    <div className="elementor-widget-container">
                                        <div className="elementor-spacer">
                                            <div className="elementor-spacer-inner" />
                                        </div>
                                    </div>
                                </div>

                                {/* <div className="sc_layouts_item elementor-element elementor-element-8264f13 sc_fly_static elementor-widget elementor-widget-text-editor trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"   >
                                    <div className="elementor-widget-container">
                                        <p><strong style={{ color: "white" }}>RJ MEEIT EVENTS</strong><br />ADDRESS : 804 SNS ARISTA, OPP. HAPPY RESIDENCY, BH. PRIME SHOPPERS, VESU SURAT 395007</p>
                                    </div>
                                </div> */}


                                <div className="sc_layouts_item elementor-element elementor-element-8264f13 sc_fly_static elementor-widget elementor-widget-text-editor trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"   >
                                    <div className="elementor-widget-container mb-2">
                                        {/* <p >RJ MEEIT EVENTS<br />804 SNS ARISTA,<br /> OPP. HAPPY RESIDENCY,<br /> BH. PRIME SHOPPERS,<br />VESU SURAT 395007</p> */}
                                        <p><strong style={{ color: "white" }}>RJ MEEIT EVENTS</strong></p>
                                    </div>

                                    <div className="elementor-widget-container">
                                        {/* <p >RJ MEEIT EVENTS<br />804 SNS ARISTA,<br /> OPP. HAPPY RESIDENCY,<br /> BH. PRIME SHOPPERS,<br />VESU SURAT 395007</p> */}
                                        <p>ADDRESS : 804 SNS ARISTA, OPP. HAPPY RESIDENCY, BH. PRIME SHOPPERS, VESU SURAT 395007</p>
                                    </div>
                                </div>


                                <div className="sc_layouts_item elementor-element elementor-element-4dc2c77 sc_layouts_hide_on_mobile sc_fly_static elementor-widget elementor-widget-spacer trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"   >
                                    <div className="elementor-widget-container">
                                        <div className="elementor-spacer">
                                            <div className="elementor-spacer-inner" />
                                        </div>
                                    </div>
                                </div>
                                <div className="sc_layouts_item elementor-element elementor-element-afc426c sc_fly_static elementor-widget elementor-widget-text-editor trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"   >
                                    <div className="elementor-widget-container">
                                        <a className="underline_anim underline_do_hover custom_links_list_item_title" href="#">INFO@RJMEEIT.COM</a>						</div>
                                </div>
                                <div className="sc_layouts_item elementor-element elementor-element-e7a9f60 sc_fly_static elementor-widget elementor-widget-spacer trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"   >
                                    <div className="elementor-widget-container">
                                        <div className="elementor-spacer">
                                            <div className="elementor-spacer-inner" />
                                        </div>
                                    </div>
                                </div>
                                <div className="sc_layouts_item elementor-element elementor-element-374feaf sc_fly_static elementor-widget elementor-widget-text-editor trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"   >
                                    <div className="elementor-widget-container">
                                        <span className="trx_addons_alter_text" style={{ fontSize: "1em" }}><NavLink to={"tel:+91 9687609704"}>+91 9687609704</NavLink></span>	<br />
                                        <span className="trx_addons_alter_text" style={{ fontSize: "1em" }}><NavLink to={"tel:+91 9998683062"}>+91 9998683062</NavLink></span>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='footerCol3'>
                            <div className="elementor-widget-wrap elementor-element-populated">
                                <div className="sc_layouts_item elementor-element elementor-element-7fac00b sc_fly_static elementor-widget elementor-widget-trx_sc_title trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"   >
                                    <div className="elementor-widget-container">
                                        <div className="sc_title sc_title_default"><h6 className="sc_item_title sc_title_title sc_item_title_style_default sc_item_title_tag"><span className="sc_item_title_text">Links</span></h6></div>		</div>
                                </div>
                                <div className="sc_layouts_item elementor-element elementor-element-ade2418 sc_fly_static elementor-widget elementor-widget-spacer trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"   >
                                    <div className="elementor-widget-container">
                                        <div className="elementor-spacer">
                                            <div className="elementor-spacer-inner" />
                                        </div>
                                    </div>
                                </div>
                                <div className="sc_layouts_item elementor-element elementor-element-7cb7a15 sc_fly_static elementor-widget elementor-widget-trx_widget_custom_links trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"   >
                                    <div className="elementor-widget-container">
                                        <div className="widget_area sc_widget_custom_links"><aside className="widget widget_custom_links"><ul className="costome_link_list_self custom_links_list">
                                            <li className="custom_links_list_item"><NavLink className="custom_links_list_item_link" to={"/RJmeeit/Home"}><span className="custom_links_list_item_title">Home</span></NavLink></li>
                                            <li className="custom_links_list_item"><NavLink className="custom_links_list_item_link" to={"/RJmeeit/About"}><span className="custom_links_list_item_title">About Us</span></NavLink></li>
                                            <li className="custom_links_list_item"><NavLink className="custom_links_list_item_link" to={"/RJmeeit/Portfolio"}><span className="custom_links_list_item_title">Achievement</span></NavLink></li>
                                            {/* <li className="custom_links_list_item"><NavLink className="custom_links_list_item_link" to={"/RJmeeit/Achievment"}><span className="custom_links_list_item_title">Achievement</span></NavLink></li> */}
                                            <li className="custom_links_list_item"><NavLink className="custom_links_list_item_link" to={"/RJmeeit/Team"}><span className="custom_links_list_item_title">Team</span></NavLink></li>
                                            <li className="custom_links_list_item"><NavLink className="custom_links_list_item_link" to={"/RJmeeit/BookingInquiry"}><span className="custom_links_list_item_title">Booking Inquiry</span></NavLink></li>
                                            <li className="custom_links_list_item"><NavLink className="custom_links_list_item_link" to={"/RJmeeit/Contact"}><span className="custom_links_list_item_title">Contact Us</span></NavLink></li>
                                        </ul></aside></div>		</div>
                                </div>
                            </div>
                        </div>
                        <div className='footerCol4'>
                            <div className="elementor-widget-wrap elementor-element-populated">
                                <div className="sc_layouts_item elementor-element elementor-element-8016732 sc_fly_static elementor-widget elementor-widget-trx_sc_title trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"   >
                                    <div className="elementor-widget-container">
                                        <div className="sc_title sc_title_default"><h6 className="sc_item_title sc_title_title sc_item_title_style_default sc_item_title_tag"><span className="sc_item_title_text">Get in touch</span></h6></div>		</div>
                                </div>
                                <div className="sc_layouts_item elementor-element elementor-element-1be1d5e sc_fly_static elementor-widget elementor-widget-spacer trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"   >
                                    <div className="elementor-widget-container">
                                        <div className="elementor-spacer">
                                            <div className="elementor-spacer-inner" />
                                        </div>
                                    </div>
                                </div>
                                <div className="sc_layouts_item elementor-element elementor-element-46dd97b sc_fly_static elementor-widget elementor-widget-trx_widget_custom_links trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"   >
                                    <div className="elementor-widget-container">
                                        <div className="widget_area sc_widget_custom_links"><aside className="widget widget_custom_links"><ul className="costome_link_list_self custom_links_list">
                                            <li className="custom_links_list_item"><NavLink to={"https://www.instagram.com/rjmeeit_mishrra/"} className="custom_links_list_item_link" target="_blank"><span className="custom_links_list_item_title">Instagram</span></NavLink></li>
                                            <li className="custom_links_list_item"><NavLink to={"https://www.youtube.com/@RJMeeitMishrra"} className="custom_links_list_item_link" target="_blank"><span className="custom_links_list_item_title">YouTube</span></NavLink></li>
                                            <li className="custom_links_list_item"><NavLink to={"https://www.facebook.com/rjmeeitmishrra/"} className="custom_links_list_item_link" target="_blank"><span className="custom_links_list_item_title">Facebook</span></NavLink></li>

                                            <li className="custom_links_list_item"><NavLink to={"https://www.google.com/search?q=rj+meeit&sca_esv=81eb3f1053a10409&ei=TQltZ9ryH9vP1e8PrbjTUA&ved=0ahUKEwiazcyZ-MSKAxXbZ_UHHS3cFAoQ4dUDCBA&uact=5&oq=rj+meeit&gs_lp=Egxnd3Mtd2l6LXNlcnAiCHJqIG1lZWl0MhEQLhgWGMcBGAoYHhiOBRivATILEAAYgAQYhgMYigUyCxAAGIAEGIYDGIoFMgsQABiABBiGAxiKBTILEAAYgAQYhgMYigUyCBAAGIAEGKIEMgUQABjvBTIFEAAY7wUyBRAAGO8FMiAQLhgWGMcBGAoYHhiOBRivARiXBRjcBBjeBBjgBNgBAUj-BlDfA1j-BHABeACQAQCYAb4BoAHuA6oBAzAuM7gBA8gBAPgBAZgCAqACyAHCAgoQABiwAxjWBBhHmAMAiAYBkAYIugYGCAEQARgUkgcDMS4xoAfwFQ&sclient=gws-wiz-serp#lrd=0x3be04dc9a6cbbea9:0x5a97eb2cd7896609,1,,,,"} className="custom_links_list_item_link" target="_blank"><span className="custom_links_list_item_title">Reviews</span></NavLink></li>

                                            <li className="custom_links_list_item"><NavLink to={"https://wa.me/9687609704?text=Hello%20there!"} className="custom_links_list_item_link" target="_blank"><span className="custom_links_list_item_title">WhatsApp</span></NavLink></li>

                                        </ul></aside></div></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="elementor-section elementor-top-section elementor-element elementor-element-a883199 sc_layouts_row sc_layouts_row_type_compact scheme_orange_dark elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"  >
                    <div className="elementor-container elementor-column-gap-extended">
                        <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-fab0f10 sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"  >
                            <div className="elementor-widget-wrap elementor-element-populated">
                                <div className="sc_layouts_item elementor-element elementor-element-6f18447 sc_fly_static elementor-widget elementor-widget-spacer trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"   >
                                    <div className="elementor-widget-container">
                                        <div className="elementor-spacer">
                                            <div className="elementor-spacer-inner" />
                                        </div>
                                    </div>
                                </div>
                                <div className="sc_layouts_item elementor-element elementor-element-1316450 elementor-widget-divider--view-line sc_fly_static elementor-widget elementor-widget-divider trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"   >
                                    <div className="elementor-widget-container">
                                        <div className="elementor-divider">
                                            <span className="elementor-divider-separator">
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="sc_layouts_item elementor-element elementor-element-b34e559 sc_layouts_hide_on_mobile sc_fly_static elementor-widget elementor-widget-spacer trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"   >
                                    <div className="elementor-widget-container">
                                        <div className="elementor-spacer">
                                            <div className="elementor-spacer-inner" />
                                        </div>
                                    </div>
                                </div>

                                <div className="sc_layouts_item elementor-element elementor-element-f86e3eb sc_fly_static elementor-widget elementor-widget-text-editor trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"   >
                                    <div className="elementor-widget-container">
                                        <a href="https://smtechno.com/" target="_blank" rel="noopener"> {new Date().getFullYear()} <span className="underline_hover">© S M Techno Consultants Pvt. Ltd.</span></a> All rights reserved.
                                    </div>
                                </div>

                                <div className="sc_layouts_item elementor-element elementor-element-9edb3da sc_fly_static elementor-widget elementor-widget-spacer trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"   >
                                    <div className="elementor-widget-container">
                                        <div className="elementor-spacer">
                                            <div className="elementor-spacer-inner" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        </footer>
    )
}

export default Footer
