import React from 'react'
import AboutUsHome from "../../../../Assets/AboutUSHome.jpg"
import "./index.css"
import { NavLink } from 'react-router-dom'

const HomeAbout = () => {
    return (
        <section className="elementor-section elementor-top-section elementor-element elementor-element-4295be78 elementor-section-full_width elementor-section-height-min-height elementor-section-items-stretch elementor-section-height-default sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"  >
            <div className=" elementor-container elementor-column-gap-extended">
                <div className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-6a261af6 sc_content_align_inherit sc_layouts_column_icons_position_left animation_type_block sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited animated eventicity-fadeinup"   >
                    <div className="elementor-widget-wrap elementor-element-populated">
                        <div className="elementor-element elementor-element-2b796d30 sc_fly_static elementor-widget elementor-widget-image trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"   >
                            <div className="elementor-widget-container">
                                <img className='aboutImageParent pointerevent' src={AboutUsHome} title="img-72-copyright" alt="img-72-copyright" loading="lazy" />
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }} className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-65a4fd55 sc_content_align_inherit sc_layouts_column_icons_position_left animation_type_block sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited animated eventicity-fadeinup"   >
                    <div className="home-about-text-content elementor-widget-wrap elementor-element-populated">
                        <div className="elementor-element elementor-element-777a51c elementor-widget__width-initial sc_fly_static elementor-widget elementor-widget-trx_sc_title trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"  >
                            <div className="elementor-widget-container">
                                <div className="sc_title sc_title_default">
                                    <span className="sc_item_subtitle sc_title_subtitle sc_item_subtitle_above sc_item_title_style_default">About Us</span>

                                    <h1 className="bannerLineTopText_2 sc_item_title sc_item_title2 sc_item_title_style_default sc_item_title_tag">
                                        <span className="sc_item_title_text4 HomeAbout">
                                            Anchoring is my profession, but creating unforgottable memories that last a lifetime is my true passion,
                                            </span>
                                            <div className="sc_item_title_texttitle aboutmargin">
                                                " सबके मन का मीत "
                                            </div>
                                      
                                        </h1>
                                </div>
                            </div>
                        </div>
                        <section className="elementor-section elementor-inner-section elementor-element elementor-element-3e0ffd88 elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"  >
                            <div className="elementor-container elementor-column-gap-no">
                                <div className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-1b8b3008 sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"  >
                                    <div className="elementor-widget-wrap elementor-element-populated">
                                        {/* <div className="elementor-element elementor-element-2687c155 sc_fly_static elementor-widget elementor-widget-spacer trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"   >
                                            <div className="elementor-widget-container">
                                                <div className="elementor-spacer">
                                                    <div className="elementor-spacer-inner" />
                                                </div>
                                            </div>
                                        </div> */}
                                        <div className="elementor-element elementor-element-424b3520 elementor-widget__width-initial sc_fly_static elementor-widget elementor-widget-trx_sc_title trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"  >
                                            <div className="elementor-widget-container">
                                                <div className="sc_title sc_title_default"><div className="sc_item_descr sc_title_descr">
                                                </div><div className="sc_item_button sc_button_wrap sc_item_button sc_item_button_default sc_item_button_size_normal sc_title_button"><NavLink to={"/RJmeeit/About"} className="sc_button sc_button_default sc_button_size_normal sc_button_icon_left"><span className="sc_button_text"><span className="sc_button_title">Read More</span></span></NavLink></div></div>		</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>

            </div>
        </section>
    )
}

export default HomeAbout
