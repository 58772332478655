
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './App.css';
import ContactUs from './Component/ContactUs';
import Layout from './Component/Layout';
import HomePage from './Component/HomePage';
import ContactHeader from './Component/ContactUs/ContactHeader';
import AboutUs from './Component/AboutUs';
import AboutHeader from './Component/AboutUs/AboutHeader';
import Portfolio from './Component/Portfolio';
import PortfolioHeader from './Component/Portfolio/PortfolioHeader';
import Team from './Component/Team';
import TeamHeader from './Component/Team/TeamHeader';
import ErrorPage from './Component/ErrorPage';
import Achievment from './Component/Achievment';
import AchievementHeader from './Component/Achievment/AchievementHeader';
import Dashboard from './Component/DemoPage';
import BookEnquiryForm from './Component/BookEnquiryForm/inidex';
import BookEnquiryHeader from './Component/BookEnquiryForm/BookEnquiryHeader';
import BookEnquiryModuleHeader from './Component/BookEnquiryModule/BookEnquiryModuleHeader';
import BookEnquiryModule from './Component/BookEnquiryModule';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
const router = createBrowserRouter([
  {
    path: `/RJmeeit/`,
    element: <Layout />,
    errorElement: <ErrorPage />,
    children:[
      {
        path: "Contact",
        element: <ContactUs />,
        handle: { headerComponent: <ContactHeader /> },
      },
      {
        path: "Home",
        element: <HomePage />
      },
      {
        path: "About",
        element: <AboutUs />,
        handle: { headerComponent: <AboutHeader /> },
      },
      {
        path: "Team",
        element: <Team />,
        handle: { headerComponent: <TeamHeader /> },
      },
      {
        path: "Portfolio",
        element: <Portfolio />,
        // handle: { headerComponent: <PortfolioHeader /> },
      },
      // {
      //   path: "Achievment",
      //   element: <Achievment />,
      //   handle: { headerComponent: <AchievementHeader /> },
      // },
      {
        path: "BookInquiry",
        element: <BookEnquiryForm />,
        handle: { headerComponent: <BookEnquiryHeader /> },
      },
      {
        path: "BookingInquiry",
        element: <BookEnquiryModule />,
        handle: { headerComponent: <BookEnquiryModuleHeader /> },
      }
    ]
  },
  {
    path: `/RJmeeit/Home`,
    element: <HomePage />,
  },
  {
    path: `/`,
    element: <Layout />,
    children:[
      {
        path: "/",
        element: <HomePage />,
      }
    ]
   
  },
  // {
  //   path: `/`,
  //   element: <Layout />,
  //   children: [
  //     {
  //       path: "/",
  //       element: <HomePage />
  //     }
  //   ]
  // }
  // ,
  // { path:"demopage",
  //   element :<Dashboard />
  // }
 
]);
function App() {
  
  return (
    <div className="App">
    <RouterProvider router={router} />
  </div>
    
  );
}

export default App;
