import React from 'react'
import "./index.css"

const Intelligent = () => {
    return (
        <section className="elementor-section elementor-top-section elementor-element elementor-element-b29bf75 scheme_light elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="b29bf75" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
            <div className="elementor-container elementor-column-gap-extended">
                <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-aa03e51 sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="aa03e51" data-element_type="column">
                    <div className="elementor-widget-wrap elementor-element-populated intelligentcss">
                        <section className="elementor-section elementor-inner-section elementor-element elementor-element-08d8c5b elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="08d8c5b" data-element_type="section">
                            <div className="elementor-container elementor-column-gap-no">
                                <div className="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-2a9bf11 sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="2a9bf11" data-element_type="column">
                                    <div className="elementor-widget-wrap elementor-element-populated">
                                        <div className="elementor-element elementor-element-abe7c14 sc_fly_static elementor-widget elementor-widget-trx_sc_title trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="abe7c14" data-element_type="widget" data-widget_type="trx_sc_title.default">
                                            <div className="elementor-widget-container">
                                                <div className="sc_title sc_title_default"><span className="sc_item_subtitle sc_title_subtitle sc_item_subtitle_above sc_item_title_style_default"></span><h1 className="sc_item_title sc_title_title sc_item_title_style_default sc_item_title_tag"><span style={{color:"white"}} className="sc_item_title_text">Intelligent</span></h1></div>		</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="elementor-column elementor-col-75 elementor-inner-column elementor-element elementor-element-5c9cdf1 sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="5c9cdf1" data-element_type="column">
                                    <div className="elementor-widget-wrap elementor-element-populated">
                                        <div className="elementor-element elementor-element-9087826 sc_fly_static elementor-widget elementor-widget-spacer trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id={9087826} data-element_type="widget" data-widget_type="spacer.default">
                                            <div className="elementor-widget-container">
                                                <div className="elementor-spacer">
                                                    <div className="elementor-spacer-inner" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="elementor-element elementor-element-db4f942 sc_fly_static elementor-widget elementor-widget-text-editor trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="db4f942" data-element_type="widget" data-widget_type="text-editor.default">
                                            <div className="elementor-widget-container">
                                                <p style={{textAlign:"justify", paddingTop: '10px'}}>RJ Meeit constantly strives hard towards the betterment of his skills as an emcee and an organizer. He has thousands of unique gamification & hosting ideas that even the most experienced event organizer might have not heard of. His stage intelligence helps him create unique moments of happiness, effortlessly.</p>						</div>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </section>
                        <section className="elementor-section elementor-inner-section elementor-element elementor-element-2d35529 elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="2d35529" data-element_type="section">
                            <div className="elementor-container elementor-column-gap-no">
                                <div className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-ea6b4e0 sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="ea6b4e0" data-element_type="column">
                                    <div className="elementor-widget-wrap elementor-element-populated">
                                        <div className="elementor-element elementor-element-1c5961b sc_height_small sc_fly_static elementor-widget elementor-widget-spacer trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="1c5961b" data-element_type="widget" data-widget_type="spacer.default">
                                            <div className="elementor-widget-container">
                                                <div className="elementor-spacer">
                                                    <div className="elementor-spacer-inner" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="elementor-section elementor-inner-section elementor-element elementor-element-e9dd2e3 elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="e9dd2e3" data-element_type="section">
                            <div className="elementor-container elementor-column-gap-no">
                                <div className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-88f7a8f sc_extra_bg_extra_left sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="88f7a8f" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                    <div className="elementor-widget-wrap elementor-element-populated">
                                        <div className="elementor-element elementor-element-105eb8e sc_fly_static elementor-widget elementor-widget-trx_sc_events trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="105eb8e" data-element_type="widget" id="slider_controls" data-widget_type="trx_sc_events.default">
                                            <div className="elementor-widget-container">
                                                <div id="slider_controls_sc" className="sc_events sc_events_classic without_button"><div className="sc_events_columns sc_item_columns trx_addons_columns_wrap columns_padding_bottom columns_in_single_row"><div className="trx_addons_column-1_3 trx_addons_column-1_2-mobile"><div className="sc_events_item sc_item_container post_container">
                                                    <div className="sc_events_item_content">
                                                        <div className="sc_events_item_featured" style={{ backgroundImage: `url(${require("../../../Assets/Intelligent1.jpg")})`}} />
                                                        {/* <div className="sc_events_item_content_inner">
                                                            <a className="sc_events_item_link" href="https://eventicity.themerex.net/event/unforgettable-wedding/" />
                                                        </div> */}
                                                    </div>
                                                </div></div><div className="trx_addons_column-1_3 trx_addons_column-1_2-mobile"><div className="sc_events_item sc_item_container post_container">
                                                    <div className="sc_events_item_content">
                                                        <div className="sc_events_item_featured" style={{ backgroundImage: `url(${require("../../../Assets/Intelligent2.jpg")})`}} />
                                                        {/* <div className="sc_events_item_content_inner">       
                                                            <a className="sc_events_item_link" href="https://eventicity.themerex.net/event/corporate-celebrations/" />
                                                        </div> */}
                                                    </div>
                                                </div></div><div className="trx_addons_column-1_3 trx_addons_column-1_2-mobile"><div className="sc_events_item sc_item_container post_container">
                                                    <div className="sc_events_item_content">
                                                        <div className="sc_events_item_featured" style={{ backgroundImage: `url(${require("../../../Assets/Intelligent3.jpg")})`}} />
                                                        {/* <div className="sc_events_item_content_inner">
                                                            <a className="sc_events_item_link" href="https://eventicity.themerex.net/event/street-food-festival/" />
                                                        </div> */}
                                                    </div>
                                                </div></div></div></div>		</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </section>

    )
}

export default Intelligent
