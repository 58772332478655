import React from 'react'
import HomePhoto1 from "../../../../Assets/HomePhoto1.jpg"
import HomePhoto2 from "../../../../Assets/HomePhoto2.jpg"
import HomePhoto3 from "../../../../Assets/HomePhoto3.jpg"
import HomePhoto4 from "../../../../Assets/HomePhoto4.jpg"
import HomePhoto5 from "../../../../Assets/HomePhoto5.jpg"
import HomePhoto6 from "../../../../Assets/HomePhoto6.jpg"
import { NavLink } from 'react-router-dom'
import { he } from 'date-fns/locale'

const HomeGallery = () => {
    return (
        <section className="elementor-section elementor-top-section elementor-element elementor-element-ae3618e elementor-section-full_width scheme_orange_dark elementor-section-height-default elementor-section-height-default sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" >
            <div className="elementor-container elementor-column-gap-no">
                <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-b721937 sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" >
                    <div className="elementor-widget-wrap elementor-element-populated">
                        <div className="elementor-element elementor-element-af27396 sc_fly_static elementor-widget elementor-widget-trx_widget_instagram trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" >
                            <div className="elementor-widget-container">
                                <div className="widget_area sc_widget_instagram"><aside className="widget widget_instagram"><div className="widget_instagram_wrap widget_instagram_type_modern">
                                    <div className="widget_instagram_images widget_instagram_images_columns_6"><div className="widget_instagram_images_item_wrap trx_addons_inline_512163355"><div title rel="magnific" className="widget_instagram_images_item widget_instagram_images_item_type_image">
                                        <img className='pointerevent' loading="lazy" src={HomePhoto1} width={370} height={370} alt /><span className="widget_instagram_images_item_counters"><span className="widget_instagram_images_item_counter_likes trx_addons_icon-heart">15</span><span className="widget_instagram_images_item_counter_comments trx_addons_icon-comment">20</span></span></div></div><div className="widget_instagram_images_item_wrap trx_addons_inline_387640247"><div title rel="magnific" className="widget_instagram_images_item widget_instagram_images_item_type_image">
                                            <img className='pointerevent' loading="lazy" src={HomePhoto2} width={370} height={370} alt /><span className="widget_instagram_images_item_counters"><span className="widget_instagram_images_item_counter_likes trx_addons_icon-heart">38</span><span className="widget_instagram_images_item_counter_comments trx_addons_icon-comment">8</span></span></div></div><div className="widget_instagram_images_item_wrap trx_addons_inline_999175129"><div title rel="magnific" className="widget_instagram_images_item widget_instagram_images_item_type_image">
                                                <img className='pointerevent' loading="lazy" src={HomePhoto3} width={370} height={370} alt /><span className="widget_instagram_images_item_counters"><span className="widget_instagram_images_item_counter_likes trx_addons_icon-heart">78</span><span className="widget_instagram_images_item_counter_comments trx_addons_icon-comment">25</span></span></div></div><div className="widget_instagram_images_item_wrap trx_addons_inline_1695103169"><div title rel="magnific" className="widget_instagram_images_item widget_instagram_images_item_type_image">
                                                    <img className="responsive-imageaboutushome pointerevent" loading="lazy" src={HomePhoto4} width={370} height={370} alt /><span className="widget_instagram_images_item_counters"><span className="widget_instagram_images_item_counter_likes trx_addons_icon-heart">58</span><span className="widget_instagram_images_item_counter_comments trx_addons_icon-comment">7</span></span></div></div><div className="widget_instagram_images_item_wrap trx_addons_inline_1094313315"><div title rel="magnific" className="widget_instagram_images_item widget_instagram_images_item_type_image">
                                                        <img className='pointerevent' loading="lazy" src={HomePhoto5} width={370} height={370} alt /><span className="widget_instagram_images_item_counters"><span className="widget_instagram_images_item_counter_likes trx_addons_icon-heart">16</span><span className="widget_instagram_images_item_counter_comments trx_addons_icon-comment">1</span></span></div></div><div className="widget_instagram_images_item_wrap trx_addons_inline_1798363253"><div title rel="magnific" className="widget_instagram_images_item widget_instagram_images_item_type_image">
                                                            <img className='pointerevent' loading="lazy" src={HomePhoto6} width={370} height={370} alt /><span className="widget_instagram_images_item_counters"><span className="widget_instagram_images_item_counter_likes trx_addons_icon-heart">61</span><span className="widget_instagram_images_item_counter_comments trx_addons_icon-comment">28</span></span></div></div></div><div className="widget_instagram_follow_link_wrap"><NavLink to={"https://www.instagram.com/rjmeeit_mishrra/"} className="widget_instagram_follow_link sc_button" target="_blank">Follow Me</NavLink></div></div></aside></div>		</div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default HomeGallery
