import React from 'react'
import "./index.css"

const SectionSpacer = () => {
  return (
    <section className="elementor-section elementor-top-section elementor-element elementor-element-0edc1ce elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"  >
    <div className="elementor-container elementor-column-gap-extended">
      <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-dabfe41 sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"  >
        <div className="elementor-widget-wrap elementor-element-populated">
          <div className="elementor-element elementor-element-f8191d0 sc_height_huge sc_fly_static elementor-widget elementor-widget-spacer trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"   >
            <div className="elementor-widget-container">
              <div className="elementor-spacer">
                <div className="elementor-spacer-inner spaceinner" />
              </div>
            </div>
          </div>
          <div className="elementor-element elementor-element-a67221d sc_fly_static elementor-widget elementor-widget-spacer trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"   >
            <div className="elementor-widget-container">
              <div className="elementor-spacer">
                <div className="elementor-spacer-inner spaceinner" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  )
}

export default SectionSpacer
