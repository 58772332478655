import React, { useState } from 'react';
import "./index.css"

const RadioButtonList = ({ options, name, onChange, formik }) => {
    const [selectedValue, setSelectedValue] = useState('');

    // const handleChange = (event) => {
    //     setSelectedValue(event.target.value);
    //     if (onChange) {
    //         onChange(event.target.value);
    //     }
    // };

    // const handleChange = (event) => {
    //     const value = event.target.value;
    //     const currentValues = formik.values[name] || [];

    //     // If the checkbox is checked, add it to the values array
    //     if (event.target.checked) {
    //         formik.setFieldValue(name, [...currentValues, value]);
    //     } else {
    //         // If the checkbox is unchecked, remove it from the values array
    //         formik.setFieldValue(
    //             name,
    //             currentValues.filter((item) => item !== value)
    //         );
    //     }
    // };

    const handleChange = (event) => {
        const value = event.target.value;
        const currentValues = Array.isArray(formik.values[name]) ? formik.values[name] : []; // Ensure it's an array even if undefined
    
        // If the checkbox is checked, add it to the array
        if (event.target.checked) {
          formik.setFieldValue(name, [...currentValues, value]);
        } else {
          // If the checkbox is unchecked, remove it from the array
          formik.setFieldValue(
            name,
            currentValues.filter((item) => item !== value)
          );
        }
      };

    return (
        // <div className="radio-button-list">
        //     {options.map((option, index) => (
        //         <label key={index} className="radio-button">
        //             <input
        //                 type="radio"
        //                 name={name}
        //                 value={option.value}
        //                 // checked={selectedValue === option.value}
        //                 checked={formik.values[name] === option.value}
        //                 // onChange={handleChange}
        //                 onChange={formik.handleChange}
        //             />
        //             <span className="custom-radio"></span>
        //             {option.label}
        //         </label>
        //     ))}
        // </div>

        <div className="checkbox-list">
            {options.map((option, index) => (
                <label key={index} className="checkbox">
                    <input
                        type="checkbox"
                        name={name}
                        value={option.value}
                        checked={formik.values[name]?.includes(option.value)}
                        onChange={handleChange}
                    />
                    <span className="custom-checkbox"></span>
                    {option.label}
                </label>
            ))}
        </div>
    );
};

export default RadioButtonList;
