
import React from 'react';
import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api';
import { FaDirections } from 'react-icons/fa';
// const containerStyle = {
//     width: '96%',
//     height: '400px'
// };

const libraries = ['places'];
// const center = {
//     lat: 21.13136, // Example latitude
//     lng: 72.85702 // Example longitude
// };



const MapComponent = ({ width, height, latitude, longitude, Address, className }) => {

    const mapContainerStyle = {
        width: width,
        height: height,// 80% of viewport height,
        // margin: "auto"
    };
    const zoom = 13

    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: 'AIzaSyCHWhwpVwgDuwit10HARKJ2eS1IjrSmHeU',
        libraries,
    });

    if (loadError) {
        return <div>Error loading maps</div>;
    }
    if (!isLoaded) {
        return;
    }
    const handleDirectionClick = () => {
        // Construct the URL for Google Maps directions
        const directionsUrl = `https://www.google.com/maps/dir/?api=1&destination=${latitude},${longitude}`;
        window.open(directionsUrl, '_blank'); // Open in a new tab
    };


    return (
        <div className={className}>
            <GoogleMap
                mapContainerStyle={mapContainerStyle}
                zoom={zoom}
                center={{
                    lat: latitude, // Example latitude
                    lng: longitude // Example longitude
                }}
            >
                <Marker position={{ lat: latitude, lng: longitude }}
                />
                <CustomOverlay
                    latitude={latitude}
                    longitude={longitude}
                    Address={Address}
                    onClick={handleDirectionClick}
                />
            </GoogleMap>
        </div>
    );

}

export default MapComponent

const CustomOverlay = ({ latitude, longitude, Address, onClick }) => (
    <div
        style={{
            position: 'absolute',
            top: '10px', // Position from the top
            left: '10px', // Position from the left
            backgroundColor: 'white',
            border: '1px solid #ddd',
            borderRadius: '8px',
            padding: '15px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
            zIndex: 1,
            maxWidth: '230px',
            maxHeight: '200px',
            fontSize: "13px",
            fontFamily: 'Arial, sans-serif',
            color: '#333',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start'
        }}
    >
        <p style={{ margin: '0 0 10px', fontWeight: 'bold', textAlign: "left" }}>{Address}</p>
        <button
            onClick={onClick}
            style={{
                display: 'flex',
                alignItems: 'center',
                backgroundColor: '#007bff',
                border: 'none',
                borderRadius: '5px',
                color: 'white',
                cursor: 'pointer',
                padding: '8px 12px',
                fontSize: '10px',
                fontWeight: 'bold',
                outline: 'none',
                transition: 'background-color 0.3s ease',
            }}
        >
            <FaDirections style={{ marginRight: '8px' }} />
            Get Directions
        </button>
    </div>
);
