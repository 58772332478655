import React from 'react'
import "./index.css"
const Entertaining = () => {
  return (
    <section className="elementor-section elementor-top-section elementor-element elementor-element-d19c2d1 elementor-section-full_width elementor-section-height-default elementor-section-height-default sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="d19c2d1" data-element_type="section">
      <div className="elementor-container elementor-column-gap-no">
        <div className="entertainingimgcss elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-db04a7b sc_column_fixed sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="db04a7b" data-element_type="column">
          <div className="elementor-widget-wrap elementor-element-populated">
            <div className="elementor-element elementor-element-585408a sc_layouts_hide_on_mobile sc_fly_static elementor-widget elementor-widget-spacer trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="585408a" data-element_type="widget" data-widget_type="spacer.default">
              <div className="elementor-widget-container">
                <div className="elementor-spacer">
                  <div className="elementor-spacer-inner" />
                </div>
              </div>
            </div>
            <div data-animation-type="block" className="elementor-element elementor-element-76d7922 animation_type_block sc_fly_static elementor-widget elementor-widget-image trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited animated eventicity-fadeinup" data-id="76d7922" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;eventicity-fadeinup&quot;}" data-widget_type="image.default">
              <div className="elementor-widget-container">
                <img decoding="async" src={require("../../../Assets/Entertaining.jpg")} className='pointerevent' title="img-116-copyright" alt="img-116-copyright" loading="lazy" />													</div>
            </div>
            <div className="elementor-element elementor-element-8f1fdb6 sc_layouts_hide_on_mobile sc_fly_static elementor-widget elementor-widget-spacer trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="8f1fdb6" data-element_type="widget" data-widget_type="spacer.default">
              <div className="elementor-widget-container">
                <div className="elementor-spacer">
                  <div className="elementor-spacer-inner" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-76ff0f4 sc_inner_width_1_2 sc-mobile_inner_width_none sc-tablet_inner_width_none sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="76ff0f4" data-element_type="column">
          <div className="elementor-widget-wrap elementor-element-populated">
            <div className="elementor-element elementor-element-ad50715 sc_fly_static elementor-widget elementor-widget-spacer trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="ad50715" data-element_type="widget" data-widget_type="spacer.default">
              <div className="elementor-widget-container">
                <div className="elementor-spacer">
                  <div className="elementor-spacer-inner" />
                </div>
              </div>
            </div>
            <div data-animation-type="block" className="elementor-element elementor-element-89178ca animation_type_block sc_fly_static elementor-widget elementor-widget-trx_sc_title trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited animated eventicity-fadeinup" data-id="89178ca" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;eventicity-fadeinup&quot;}" data-widget_type="trx_sc_title.default">
              <div className="elementor-widget-container">
                <div className="entertainingself sc_title sc_title_default"><h1 className="sc_item_title sc_title_title sc_item_title_style_default sc_item_title_tag"><span className="sc_item_title_text">Entertaining</span></h1><div className="sc_item_descr sc_title_descr"><p style={{ textAlign: "justify" }}>There’s never a dull moment when RJ Meeit takes the stage, as his exceptional communication skills and meticulous stage management ensure an engaging and seamless experience for all. RJ Meeit possesses an impressive ability to adapt effortlessly to both formal and informal tones, catering to the unique needs of every event. His expertise guarantees a well-organized, stress-free atmosphere, leaving the audience and hosts worry-free while ensuring the event runs smoothly. With RJ Meeit at the helm, you can expect a truly enjoyable and professionally managed occasion that resonates with everyone in attendance.</p>
                </div></div>		</div>
            </div>
            <div className="elementor-element elementor-element-6eeed29 sc_fly_static elementor-widget elementor-widget-spacer trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="6eeed29" data-element_type="widget" data-widget_type="spacer.default">
              <div className="elementor-widget-container">
                <div className="elementor-spacer">
                  <div className="elementor-spacer-inner" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  )
}

export default Entertaining
