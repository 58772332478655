import React from 'react'
import Marquee from "react-fast-marquee";

import "./index.css"
const logoScroll = [
    { src: require("../../../../LogoAssets/logo (1).jpg"), alt: "logo (1)" },
    { src: require("../../../../LogoAssets/logo (2).jpg"), alt: "logo (2)" },
    { src: require("../../../../LogoAssets/logo (3).jpg"), alt: "logo (3)" },
    { src: require("../../../../LogoAssets/logo (4).jpg"), alt: "logo (4)" },
    { src: require("../../../../LogoAssets/logo (5).jpg"), alt: "logo (5)" },
    { src: require("../../../../LogoAssets/logo (6).jpg"), alt: "logo (6)" },
    { src: require("../../../../LogoAssets/logo (7).jpg"), alt: "logo (7)" },
    { src: require("../../../../LogoAssets/logo (8).jpg"), alt: "logo (8)" },
    { src: require("../../../../LogoAssets/logo (9).jpg"), alt: "logo (9)" },
    { src: require("../../../../LogoAssets/logo (10).jpg"), alt: "logo (10)" },
    { src: require("../../../../LogoAssets/logo (11).jpg"), alt: "logo (11)" },
    { src: require("../../../../LogoAssets/logo (12).jpg"), alt: "logo (12)" },
    { src: require("../../../../LogoAssets/logo (13).jpg"), alt: "logo (13)" },
    { src: require("../../../../LogoAssets/logo (14).jpg"), alt: "logo (14)" },
    { src: require("../../../../LogoAssets/logo (15).jpg"), alt: "logo (15)" },
    { src: require("../../../../LogoAssets/logo (16).jpg"), alt: "logo (16)" },
    { src: require("../../../../LogoAssets/logo (17).jpg"), alt: "logo (17)" },
    { src: require("../../../../LogoAssets/logo (18).jpg"), alt: "logo (18)" },
    { src: require("../../../../LogoAssets/logo (19).jpg"), alt: "logo (19)" },
    { src: require("../../../../LogoAssets/logo (20).jpg"), alt: "logo (20)" },
    { src: require("../../../../LogoAssets/logo (21).jpg"), alt: "logo (21)" },
    { src: require("../../../../LogoAssets/logo (22).jpg"), alt: "logo (22)" },
    { src: require("../../../../LogoAssets/logo (23).jpg"), alt: "logo (23)" },
    { src: require("../../../../LogoAssets/logo (24).jpg"), alt: "logo (24)" },
    { src: require("../../../../LogoAssets/logo (25).jpg"), alt: "logo (25)" }
]

const LogoLivescroll = () => {
    return (
        <section className="elementor-section elementor-top-section elementor-element elementor-element-6a652c2 elementor-section-full_width elementor-section-height-default elementor-section-height-default sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"  >
            <div className="elementor-container elementor-column-gap-no">
                <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-229b4b0 sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"  >
                    <div className="elementor-widget-wrap elementor-element-populated">
                        <div className="elementor-element elementor-element-6893a04 sc_height_huge sc_fly_static elementor-widget elementor-widget-spacer trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"   >
                            <div className="elementor-widget-container">
                                <div className="elementor-spacer">
                                    <div className="elementor-spacer-inner" />
                                </div>
                            </div>
                        </div>
                        {/* <div className="elementor-element elementor-element-8a9b078 sc_fly_static elementor-widget elementor-widget-spacer trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"   >
                            <div className="elementor-widget-container">
                                <div className="elementor-spacer">
                                    <div className="elementor-spacer-inner" />
                                </div>
                            </div>
                        </div> */}
                        <Marquee>
                            {logoScroll.map(ele => {
                                return (
                                    <div className='liveScrollLogo' style={{ marginRight: "50px", height: "120px", width: "180px", display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "white" }} ><img className='liveScrollLogo' style={{ maxHeight: "100px", maxWidth: "120px", pointerEvents: 'none'}} src={ele.src} alt={ele.alt} /></div>
                                )
                            })}
                        </Marquee>
                        {/* <div className="elementor-element elementor-element-ae932e9 sc_height_huge sc_fly_static elementor-widget elementor-widget-spacer trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"   >
                            <div className="elementor-widget-container">
                                <div className="elementor-spacer">
                                    <div className="elementor-spacer-inner" />
                                </div>
                            </div>
                        </div>
                        <div className="elementor-element elementor-element-72fbf36 sc_fly_static elementor-widget elementor-widget-spacer trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"   >
                            <div className="elementor-widget-container">
                                <div className="elementor-spacer">
                                    <div className="elementor-spacer-inner" />
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default LogoLivescroll
