import React, { useEffect } from 'react'
import Header from '../Header'
import { Outlet, useLocation, useMatches } from 'react-router-dom'
import Footer from '../Footer';
import MobileNav from '../MobileNav';
import VideoModel from '../VideoModel';

const Layout = () => {
  const matches = useMatches();
  const location = useLocation();
  const currentRoute = matches[matches.length - 1]?.handle || {};
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', 
    });
  };
const hideHeaderFooter =location?.pathname?.toLowerCase() =="/rjmeeit/bookinquiry"

  useEffect(() => {     
      const handleKeyDown = (event) => {
        if (event.key === "F12" || event.keyCode === 123) {
          event.preventDefault();            
        }
      };   
      
      const handleContextMenu = (event) => {
        event.preventDefault();          
      };    
    
      document.addEventListener("keydown", handleKeyDown);
      document.addEventListener("contextmenu", handleContextMenu);
  
      return () => {
        document.removeEventListener("keydown", handleKeyDown);
        document.removeEventListener("contextmenu", handleContextMenu);
      };
    }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
  <div  style={{ userSelect: "none" }} className="page-template-default page page-id-52477 wp-custom-logo theme-eventicity woocommerce-no-js tribe-no-js tinvwl-theme-style skin_default scheme_orange_dark blog_mode_page body_style_fullscreen is_single sidebar_hide expand_content remove_margins trx_addons_present header_type_custom header_style_header-custom-58276 header_position_over menu_side_none no_layout fixed_blocks_sticky elementor-default elementor-kit-15 elementor-page elementor-page-52477">
    <VideoModel />
      <div className="body_wrap">
     <a onClick={()=>scrollToTop()} className="trx_addons_scroll_to_top trx_addons_icon-up scroll_to_top_style_default inited show" title="Scroll to top" />

        <div  className={`eventicity_inline_1100695198  page_wrap with_bg ${hideHeaderFooter ? "mainApplayout1": ""}`}>
      {!hideHeaderFooter ? <Header headerComponent={currentRoute.headerComponent}  /> :  currentRoute.headerComponent }
          <MobileNav />
          <Outlet  />
         {!hideHeaderFooter &&  <Footer />}
        </div>
      </div>
    </div>
  )
}

export default Layout
