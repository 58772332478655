import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useLocation } from 'react-router-dom'
import { changeMobileNavStatus } from '../../Redux/Slices'
import "./index.css"

const Header = ({ headerComponent }) => {
  const dispatch = useDispatch()
  const location = useLocation()
  let currentpath = location.pathname.split("/")?.[2]?.toLowerCase()
  console.log(currentpath, "currentpath")
  // const {mobileNavOpen}=useSelector(state=>state.MainSlice)
  const handleOpenMobileNav = () => {
    // console.log()
    dispatch(changeMobileNavStatus(true))
  }
  return (
    <>
      <header className="topHeaderPanelSelf top_panel top_panel_custom top_panel_custom_58276 top_panel_custom_header-main-inverse-style-2				 without_bg_image">
        <div className="elementor elementor-58276">
          <section className="elementor-section elementor-top-section elementor-element elementor-element-e883e88 elementor-section-full_width elementor-section-content-middle sc_layouts_row sc_layouts_row_type_compact sc_layouts_hide_on_mobile sc_layouts_hide_on_tablet elementor-section-height-default elementor-section-height-default sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" >
            <div className="elementor-container elementor-column-gap-extended">
              <div className="elementor-column elementor-col-66 elementor-top-column elementor-element elementor-element-a25ebbb sc_layouts_column_align_left sc_layouts_column sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" >
                <div className="elementor-widget-wrap elementor-element-populated">
                  <div className="sc_layouts_item elementor-element elementor-element-7ff1bc6 sc_fly_static elementor-widget elementor-widget-trx_sc_layouts_logo trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" >
                    <div className="elementor-widget-container">
                      <NavLink to={"/RJmeeit/Home"} className="sc_layouts_logo sc_layouts_logo_default trx_addons_inline_1067121469">
                        <h2 className="sc_layouts_title_caption">RJ Meeit</h2>
                        <h6 className='subtitlecss'>#FamilyAnchor</h6>
                        {/* <h6 className='subtitlecss'>#FamilyAnchorRJMeeit</h6> */}
                        {/* <img className="logo_image" src={require("../../Assets/logo-inverse-2x.jpg")} alt="RJmeeit" width={177} height={51} /> */}
                      </NavLink>
                    </div>
                  </div>
                  <div className="sc_layouts_item elementor-element elementor-element-d7d1e24 sc_fly_static elementor-widget elementor-widget-trx_sc_layouts_menu trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" >
                    <div className="elementor-widget-container">
                      <nav className="sc_layouts_menu sc_layouts_menu_default sc_layouts_menu_dir_horizontal menu_hover_zoom_line inited inited_kbd" ><ul id="menu_main" className="sc_layouts_menu_nav_self sc_layouts_menu_nav menu_main_nav inited_cache inited sf-js-enabled sf-arrows"><li id="menu-item-18077" className={`menu-item menu-item-type-custom ${currentpath == "home" ? "menu-item-object-custom current-menu-ancestor current-menu-parent" : ""} menu-item-has-children menu-item-18077`}><NavLink to={"/RJmeeit/Home"} className="sf-with-ul"><span>Home</span></NavLink>
                      </li>
                        <li id="menu-item-18098" className={`menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children ${currentpath == "about" ? "menu-item-object-custom current-menu-ancestor current-menu-parent" : ""} menu-item-18098`}><NavLink to={"/RJmeeit/About"} className="sf-with-ul"><span>About Us</span></NavLink>
                        </li>
                        <li id="menu-item-52451" className={`menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children ${currentpath == "portfolio" ? "menu-item-object-custom current-menu-ancestor current-menu-parent" : ""} menu-item-52451`}><NavLink to={"/RJmeeit/Portfolio"} className="sf-with-ul"><span>Achievment</span></NavLink>
                        </li>
                        {/* <li id="menu-item-52451" className={`menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children ${currentpath == "achievment" ? "menu-item-object-custom current-menu-ancestor current-menu-parent" : ""} menu-item-52451`}><NavLink to={"/RJmeeit/Achievment"} className="sf-with-ul"><span>Achievment</span></NavLink>
                        </li> */}
                        <li id="menu-item-52451" className={`menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children ${currentpath == "team" ? "menu-item-object-custom current-menu-ancestor current-menu-parent" : ""} menu-item-52451`}><NavLink to={"/RJmeeit/Team"} className="sf-with-ul"><span>Team</span></NavLink>
                        </li>

                        <li id="menu-item-52451" className={`menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children ${currentpath == "bookinginquiry" ? "menu-item-object-custom current-menu-ancestor current-menu-parent" : ""} menu-item-52451`}><NavLink to={"/RJmeeit/BookingInquiry"} className="sf-with-ul"><span>Booking Inquiry</span></NavLink>
                        </li>

                        <li id="menu-item-18101" className={`menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children ${currentpath == "contact" ? "menu-item-object-custom current-menu-ancestor current-menu-parent" : ""} menu-item-18101`}><NavLink to={"/RJmeeit/Contact"} className="sf-with-ul"><span>Contact Us</span></NavLink>
                        </li><li className="menu-item menu-collapse" ><a href="#" className="sf-with-ul-self sf-with-ul trx_addons_icon-ellipsis-vert" /><ul className="submenu" /></li></ul></nav>		</div>
                  </div>
                </div>
              </div>

            </div>
          </section>
          <section style={{ backgroundColor: "#161515" }} className="elementor-section elementor-top-section elementor-element elementor-element-136436d elementor-section-content-middle sc_layouts_row sc_layouts_row_type_compact sc_layouts_hide_on_wide sc_layouts_hide_on_desktop sc_layouts_hide_on_notebook elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" >
            <div className="elementor-container elementor-column-gap-no">
              <div className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-1e7cdd9 sc_layouts_column_align_left sc_layouts_column sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" >
                <div className="elementor-widget-wrap elementor-element-populated">
                  <div className="sc_layouts_item elementor-element elementor-element-e50c31d sc_fly_static elementor-widget elementor-widget-trx_sc_layouts_logo trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" >
                    <div className="elementor-widget-container">
                      <NavLink to={"/RJmeeit/Home"} className="sc_layouts_logo sc_layouts_logo_default trx_addons_inline_1411042142">
                        <h2 className="sc_layouts_title_caption">RJ Meeit</h2>
                        <h6 className='subtitlecss'>#FamilyAnchor</h6>
                        {/* <h6 className='subtitlecss'>#FamilyAnchorRJMeeit</h6> */}
                        {/* <img className="logo_image" src={require("../../Assets/logo-inverse-2x.jpg")}  alt="RJmeeit" width={177} height={51} /> */}
                      </NavLink>		</div>
                  </div>
                </div>
              </div>
              <div className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-36e49d9 sc_layouts_column_align_right sc_layouts_column sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" >
                <div className="elementor-widget-wrap elementor-element-populated">
                  <div className="sc_layouts_item elementor-element elementor-element-4a539fa sc_fly_static elementor-widget elementor-widget-trx_sc_layouts_menu trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" >
                    <div className="elementor-widget-container">
                      <div className="sc_layouts_iconed_text sc_layouts_menu_mobile_button_burger sc_layouts_menu_mobile_button without_menu">
                        <a className="sc_layouts_item_link sc_layouts_iconed_text_link inited_click" href="#">
                          <span onClick={handleOpenMobileNav} className="sc_layouts_item_icon sc_layouts_iconed_text_icon trx_addons_icon-menu" />
                        </a>
                      </div>		</div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </header>
      {headerComponent}
    </>


  )
}

export default Header
