import React from 'react'
import Team1 from "../../Assets/Team1.jpg"
import Team2 from "../../Assets/Team2.jpg"
import "./index.css"
const Team = () => {
  return (
    <div className="page_content_wrap">
      <div className="content_wrap_fullscreen aboutPadding">
        <div className="content">
          <a id="content_skip_link_anchor" className="eventicity_skip_link_anchor" href="#" />
          <article id="post-18062" className="post_item_single post_type_page post-18062 page type-page status-publish hentry">
            <div className="post_content entry-content">
              <div className="elementor elementor-18062">

                <section className="elementor-section elementor-inner-section elementor-element elementor-element-5e0f9db elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited">
                  <div className="elementor-container elementor-column-gap-no">
                    <div className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-b86f6b8 sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"  >
                      <div className="elementor-widget-wrap elementor-element-populated">

                        <div className="trx_addons_column-1_3 elementor-element elementor-element-e81b0e1 sc_height_small sc_fly_static elementor-widget elementor-widget-spacer trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"  >
                          <div className="elementor-widget-container">
                            <div className="elementor-spacer">
                              <div className="elementor-spacer-inner" />
                            </div>
                          </div>
                        </div>
                        <div className="elementor-element elementor-element-d37cfd3 animation_type_sequental sc_fly_static elementor-widget elementor-widget-trx_sc_team animated-separate trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited mrbspace"  >
                          <div className="elementor-widget-container">
                            {/* <div className="sc_team sc_team_short row swiper-wrapper"> */}
                            <div className="sc_team sc_team_short row justifycenter">
                              {/* <Swiper
                                            slidesPerView={3}
                                            spaceBetween={30}
                                            className="mySwiper"
                                        > */}

                              {/* <SwiperSlide > */}
                              <div className="sc_team_item sc_item_container post_container no_links post-54058 cpt_team type-cpt_team status-publish has-post-thumbnail hentry cpt_team_group-speakers animated-item animated eventicity-fadeinup col-md-4" >
                                <div className="post_featured sc_team_item_thumb trx_addons_hover trx_addons_hover_style_info_anim imgchnage pointerevent"><img loading="lazy" width={570} height={696} src={Team1} className="attachment-eventicity-thumb-rectangle size-eventicity-thumb-rectangle wp-post-image" alt /><div className="trx_addons_hover_team"><div className="sc_team_item_socials socials_wrap trx_addons_hover_info">
                                  {/* <a target="_blank" href="#" className="social_item social_item_style_icons sc_icon_type_icons social_item_type_icons"><span className="social_icon social_icon_facebook" ><span className="icon-facebook" /></span></a>
                                                        <a target="_blank" href="#" className="social_item social_item_style_icons sc_icon_type_icons social_item_type_icons"><span className="social_icon social_icon_twitter-x" ><span className="trx_addons_icon-twitter-x" /></span></a>
                                                        <a target="_blank" href="#" className="social_item social_item_style_icons sc_icon_type_icons social_item_type_icons"><span className="social_icon social_icon_dribbble-1" ><span className="icon-dribbble-1" /></span></a>
                                                        <a target="_blank" href="#" className="social_item social_item_style_icons sc_icon_type_icons social_item_type_icons"><span className="social_icon social_icon_instagramm" ><span className="icon-instagramm" /></span></a> */}
                                </div></div><a className="post_link sc_team_item_link" href="#" /><div className="trx_addons_hover_mask" /></div>	<div className="sc_team_item_info">
                                  <div className="sc_team_item_header">
                                    <h4 className="sc_team_item_title entry-title"><a href="#">RIKUNJ PATEL</a></h4>
                                    {/* <div className="sc_team_item_subtitle">Catering manager</div>	 */}
                                  </div>
                                </div>
                              </div>
                              {/* </SwiperSlide> */}

                              {/* <SwiperSlide > */}
                              <div className="sc_team_item sc_item_container post_container no_links post-54057 cpt_team type-cpt_team status-publish has-post-thumbnail hentry cpt_team_group-speakers animated-item animated eventicity-fadeinup col-md-4" >
                                <div className="post_featured sc_team_item_thumb trx_addons_hover trx_addons_hover_style_info_anim imgchnage pointerevent"><img loading="lazy" width={570} height={696} src={Team2} className="attachment-eventicity-thumb-rectangle size-eventicity-thumb-rectangle wp-post-image" alt />
                                  <div className="trx_addons_hover_team"><div className="sc_team_item_socials socials_wrap trx_addons_hover_info">
                                    {/* <a target="_blank" href="#" className="social_item social_item_style_icons sc_icon_type_icons social_item_type_icons"><span className="social_icon social_icon_facebook" ><span className="icon-facebook" /></span></a>
                                                    <a target="_blank" href="#" className="social_item social_item_style_icons sc_icon_type_icons social_item_type_icons"><span className="social_icon social_icon_twitter-x" ><span className="trx_addons_icon-twitter-x" /></span></a>
                                                    <a target="_blank" href="#" className="social_item social_item_style_icons sc_icon_type_icons social_item_type_icons"><span className="social_icon social_icon_dribbble-1" ><span className="icon-dribbble-1" /></span></a>
                                                    <a target="_blank" href="#" className="social_item social_item_style_icons sc_icon_type_icons social_item_type_icons"><span className="social_icon social_icon_instagramm" ><span className="icon-instagramm" /></span></a> */}
                                  </div></div><a className="post_link sc_team_item_link" href="#" /><div className="trx_addons_hover_mask" /></div>	<div className="sc_team_item_info">
                                  <div className="sc_team_item_header">
                                    <h4 className="sc_team_item_title entry-title"><a href="#">FAHIM AKHTAR</a></h4>
                                    {/* <div className="sc_team_item_subtitle">Venue coordinator</div>	 */}
                                  </div>
                                </div>
                              </div>
                              {/* </SwiperSlide> */}


                              {/* <SwiperSlide><div className="sc_team_item sc_item_container post_container no_links post-54056 cpt_team type-cpt_team status-publish has-post-thumbnail hentry cpt_team_group-speakers animated-item animated eventicity-fadeinup" >
                                                <div className="post_featured sc_team_item_thumb trx_addons_hover trx_addons_hover_style_info_anim"><img loading="lazy"  width={570} height={696} src={Team3} className="attachment-eventicity-thumb-rectangle size-eventicity-thumb-rectangle wp-post-image" alt /><div className="trx_addons_hover_team"><div className="sc_team_item_socials socials_wrap trx_addons_hover_info"><a target="_blank" href="#" className="social_item social_item_style_icons sc_icon_type_icons social_item_type_icons"><span className="social_icon social_icon_facebook" ><span className="icon-facebook" /></span></a><a target="_blank" href="#" className="social_item social_item_style_icons sc_icon_type_icons social_item_type_icons"><span className="social_icon social_icon_twitter-x" ><span className="trx_addons_icon-twitter-x" /></span></a><a target="_blank" href="#" className="social_item social_item_style_icons sc_icon_type_icons social_item_type_icons"><span className="social_icon social_icon_dribbble-1" ><span className="icon-dribbble-1" /></span></a><a target="_blank" href="#" className="social_item social_item_style_icons sc_icon_type_icons social_item_type_icons"><span className="social_icon social_icon_instagramm" ><span className="icon-instagramm" /></span></a></div></div><a className="post_link sc_team_item_link" href="#" /><div className="trx_addons_hover_mask" /></div>	<div className="sc_team_item_info">
                                                    <div className="sc_team_item_header">
                                                        <h4 className="sc_team_item_title entry-title"><a href="#">JAY BARADIA</a></h4> */}
                              {/* <div className="sc_team_item_subtitle">Decor specialist</div>	 */}
                              {/* </div>
                                                </div>
                                            </div>
                                            </SwiperSlide> */}
                              {/* <SwiperSlide><div className="sc_team_item sc_item_container post_container no_links post-54065 cpt_team type-cpt_team status-publish has-post-thumbnail hentry cpt_team_group-speakers animated-item animated eventicity-fadeinup" >
                                                <div className="post_featured sc_team_item_thumb trx_addons_hover trx_addons_hover_style_info_anim"><img loading="lazy"  width={570} height={696} src={Team4} className="attachment-eventicity-thumb-rectangle size-eventicity-thumb-rectangle wp-post-image" alt /><div className="trx_addons_hover_team"><div className="sc_team_item_socials socials_wrap trx_addons_hover_info"><a target="_blank" href="#" className="social_item social_item_style_icons sc_icon_type_icons social_item_type_icons"><span className="social_icon social_icon_facebook" ><span className="icon-facebook" /></span></a><a target="_blank" href="#" className="social_item social_item_style_icons sc_icon_type_icons social_item_type_icons"><span className="social_icon social_icon_twitter-x" ><span className="trx_addons_icon-twitter-x" /></span></a><a target="_blank" href="#" className="social_item social_item_style_icons sc_icon_type_icons social_item_type_icons"><span className="social_icon social_icon_dribbble-1" ><span className="icon-dribbble-1" /></span></a><a target="_blank" href="#" className="social_item social_item_style_icons sc_icon_type_icons social_item_type_icons"><span className="social_icon social_icon_instagramm" ><span className="icon-instagramm" /></span></a></div></div><a className="post_link sc_team_item_link" href="#" /><div className="trx_addons_hover_mask" /></div>	<div className="sc_team_item_info">
                                                    <div className="sc_team_item_header">
                                                        <h4 className="sc_team_item_title entry-title"><a href="#">Sarah Miller</a></h4>
                                                        <div className="sc_team_item_subtitle">Event manager</div>	\
                                                        	</div>
                                                </div>
                                            </div>
                                            </SwiperSlide>
                                            <SwiperSlide><div className="sc_team_item sc_item_container post_container no_links post-54066 cpt_team type-cpt_team status-publish has-post-thumbnail hentry cpt_team_group-speakers animated-item animated eventicity-fadeinup" >
                                                <div className="post_featured sc_team_item_thumb trx_addons_hover trx_addons_hover_style_info_anim"><img loading="lazy"  width={570} height={696} src={Team5} className="attachment-eventicity-thumb-rectangle size-eventicity-thumb-rectangle wp-post-image" alt /><div className="trx_addons_hover_team"><div className="sc_team_item_socials socials_wrap trx_addons_hover_info"><a target="_blank" href="#" className="social_item social_item_style_icons sc_icon_type_icons social_item_type_icons"><span className="social_icon social_icon_facebook" ><span className="icon-facebook" /></span></a><a target="_blank" href="#" className="social_item social_item_style_icons sc_icon_type_icons social_item_type_icons"><span className="social_icon social_icon_twitter-x" ><span className="trx_addons_icon-twitter-x" /></span></a><a target="_blank" href="#" className="social_item social_item_style_icons sc_icon_type_icons social_item_type_icons"><span className="social_icon social_icon_dribbble-1" ><span className="icon-dribbble-1" /></span></a><a target="_blank" href="#" className="social_item social_item_style_icons sc_icon_type_icons social_item_type_icons"><span className="social_icon social_icon_instagramm" ><span className="icon-instagramm" /></span></a></div></div><a className="post_link sc_team_item_link" href="#" /><div className="trx_addons_hover_mask" /></div>	<div className="sc_team_item_info">
                                                    <div className="sc_team_item_header">
                                                        <h4 className="sc_team_item_title entry-title"><a href="#">David Taylor</a></h4>
                                                        <div className="sc_team_item_subtitle">Creative director</div>	
                                                        	</div>
                                                </div>
                                            </div>
                                            </SwiperSlide>
                                            <SwiperSlide ><div className="sc_team_item sc_item_container post_container no_links post-54067 cpt_team type-cpt_team status-publish has-post-thumbnail hentry cpt_team_group-speakers animated-item animated eventicity-fadeinup" >
                                                <div className="post_featured sc_team_item_thumb trx_addons_hover trx_addons_hover_style_info_anim"><img loading="lazy"  width={570} height={696} src={Team6} className="attachment-eventicity-thumb-rectangle size-eventicity-thumb-rectangle wp-post-image" alt /><div className="trx_addons_hover_team"><div className="sc_team_item_socials socials_wrap trx_addons_hover_info"><a target="_blank" href="#" className="social_item social_item_style_icons sc_icon_type_icons social_item_type_icons"><span className="social_icon social_icon_facebook" ><span className="icon-facebook" /></span></a><a target="_blank" href="#" className="social_item social_item_style_icons sc_icon_type_icons social_item_type_icons"><span className="social_icon social_icon_twitter-x" ><span className="trx_addons_icon-twitter-x" /></span></a><a target="_blank" href="#" className="social_item social_item_style_icons sc_icon_type_icons social_item_type_icons"><span className="social_icon social_icon_dribbble-1" ><span className="icon-dribbble-1" /></span></a><a target="_blank" href="#" className="social_item social_item_style_icons sc_icon_type_icons social_item_type_icons"><span className="social_icon social_icon_instagramm" ><span className="icon-instagramm" /></span></a></div></div><a className="post_link sc_team_item_link" href="#" /><div className="trx_addons_hover_mask" /></div>	<div className="sc_team_item_info">
                                                    <div className="sc_team_item_header">
                                                        <h4 className="sc_team_item_title entry-title"><a href="#">Nicole Davis</a></h4>
                                                        <div className="sc_team_item_subtitle">Event emcee</div>		
                                                        </div>
                                                </div>
                                            </div>
                                            </SwiperSlide> */}

                              {/* </Swiper> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

              </div>
            </div>
          </article>
        </div>
      </div>
    </div>

  )
}

export default Team

