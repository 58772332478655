
import { createSlice } from "@reduxjs/toolkit";

const MainSlice=createSlice({
   name:"main",
   initialState:{
      mobileNavOpen:false,
      curruntActive:null
   },
   reducers:{
    changeMobileNavStatus :(state,action)=>{
        state.mobileNavOpen=action.payload 
    },
    changeCurrentActive:(state,action)=>{
      state.curruntActive=action.payload
    }
  },
})

export const {changeMobileNavStatus,changeCurrentActive} = MainSlice.actions;
export default MainSlice.reducer