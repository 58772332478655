import React from 'react'
import './index.css';
import { MDBCarousel, MDBCarouselItem } from 'mdb-react-ui-kit';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
const Portfolio = () => {

    const imagePaths = [
        require("../../GalleryAssets/gallery (1).jpg"),
        require("../../GalleryAssets/gallery (2).jpg"),
        require("../../GalleryAssets/gallery (3).jpg"),
        require("../../GalleryAssets/gallery (4).jpg"),
        require("../../GalleryAssets/gallery (5).jpg"),
        require("../../GalleryAssets/gallery (6).jpg"),
        require("../../GalleryAssets/gallery (7).jpg"),
        require("../../GalleryAssets/gallery (8).jpg"),
        require("../../GalleryAssets/gallery (9).jpg"),
        require("../../GalleryAssets/gallery (10).jpg"),
        require("../../GalleryAssets/gallery (11).jpg"),
        require("../../GalleryAssets/gallery (12).jpg"),
        require("../../GalleryAssets/gallery (13).jpg"),
        require("../../GalleryAssets/gallery (14).jpg"),
        require("../../GalleryAssets/gallery (15).jpg"),
        require("../../GalleryAssets/gallery (16).jpg"),
        require("../../GalleryAssets/gallery (17).jpg"),
        require("../../GalleryAssets/gallery (18).jpg"),
        require("../../GalleryAssets/gallery (19).jpg"),
        require("../../GalleryAssets/gallery (20).jpg"),
        require("../../GalleryAssets/gallery (21).jpg"),
        require("../../GalleryAssets/gallery (22).jpg"),
        require("../../GalleryAssets/gallery (23).jpg"),
        require("../../GalleryAssets/gallery (24).jpg"),
        require("../../GalleryAssets/gallery (25).jpg"),
        require("../../GalleryAssets/gallery (26).jpg"),
        require("../../GalleryAssets/gallery (27).jpg"),
        require("../../GalleryAssets/gallery (28).jpg"),
        require("../../GalleryAssets/gallery (29).jpg"),
        require("../../GalleryAssets/gallery (30).jpg")
    ];

    return (
        <div className="container">
            {/* <div className="gallery-container"> */}
                {/* interval={2000} */}
                <MDBCarousel showControls showIndicators  >
                    {imagePaths.map((image, index) => (
                        <MDBCarouselItem itemId={index + 1} key={index}>
                            <img src={image}
                                className="carousel-image responsive-image pointerevent"
                                alt={`Slide ${index + 1}`}  />
                        </MDBCarouselItem>
                    ))}
                </MDBCarousel>
            {/* </div> */}
        </div>

    )
}

export default Portfolio

