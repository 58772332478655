import React, { useRef } from 'react'
import emailjs from "emailjs-com";
import { handleKeyDownNumber, handlePaste, notifyError, notifySuccess } from '../../HelperMethod';
import { EMAILJS_SERVICE_ID, EMAILJS_Template_ID, EMAILJS_USERID } from '../../Process';
import * as Yup from "yup";
import { toast, ToastContainer } from 'react-toastify';
import { useFormik } from 'formik'
import { DatePicker, Select, Space, TimePicker } from 'antd';
import dayjs from 'dayjs';
import { useState } from 'react';
import Loader from '../Loader';
import RadioButtonList from '../RadioButtonList';
import { timeData } from '../../Data';

const BookEnquiryForm = () => {
  const [loader, setLoader] = useState(false)
  const [open, setOpen] = useState(false)
  const isSelecting = useRef(false);
  const [formTableData, setFormTableData] = useState([
    {
      EventType: "",
      EventDate: "",
      EventStartTime: ""
    }
  ])
  const Costumeoptions = [
    { label: 'Traditional', value: 'Traditional' },
    { label: 'Western', value: 'Western' },
    { label: 'Indo-western', value: 'Indo-western' },
    { label: 'Other', value: 'Other' },
  ];

  const Languageoptions = [
    { label: 'Gujarati', value: 'Gujarati' },
    { label: 'Hindi', value: 'Hindi' },
    { label: 'English', value: 'English' }
  ];

  const initialValues = {
    CompanyFamily: "",
    Contact: "",
    Address: "",
    EmailID: "",
    EventType: "",
    EventDate: "",
    EventVenue: "",
    Requirement: "",
    EventSession: "",
    EventStartTime: "",
    RJMeeitsCostume: ["Traditional"],
    Language: ["Hindi"],
    Budget: "",
    Details: "",
    CheckButton: false
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object({
      CompanyFamily: Yup.string().required("Family/Company Name is required"),
      Contact: Yup.string()
        .matches(/^[0-9]{10}$/, "Phone must be 10 digits")
        .required("Phone is required"),
      Address: Yup.string().required("Home / Office Address is required"),
      // EventType: Yup.string().required("EventType is required"),
      // EventDate: Yup.string().required("Event Date is Required"),
      EventVenue: Yup.string().required("Event Venue Address is required"),
      //Requirement: Yup.string().required("Specific Requirement is required"),
      // EventSession: Yup.string().required("Event Session is required"),
      // EventStartTime: Yup.string().required("Event StartTime is required"),
      // Budget: Yup.string().required("Budget Is Required"),
      EmailID: Yup.string()
        .email("Invalid email address")
        .required("Email ID is required"),

      // CheckButton :Yup.string().required("Agree to the data collection and storage terms to continue.")
    }),
    onSubmit: (values) => {
      if (validateFormTableData()) {
        return
      }
      console.log(values, "valuesvalues")
      handleSubmit(values)
    }
  })
  const handleSubmit = (values) => {
    // const formData = {
    //   CompanyFamily: values?.CompanyFamily || "",
    //   Contact: values?.Contact || "",
    //   Address: values?.Address || "",
    //   EmailID: values?.EmailID || "",
    //   EventType: values?.EventType || "",
    //   EventDate: values?.EventDate ? dayjs(values?.EventDate).format("DD/MM/YYYY") : "",
    //   EventVenue: values?.EventVenue || "",
    //   Requirement: values?.Requirement || "",
    //   // EventSession: values?.EventSession || "",
    //   // EventStartTime: values?.EventStartTime ? dayjs(values?.EventStartTime).format("h:mm:ss A") : "",
    //   EventStartTime: values?.EventStartTime || "",
    //   RJMeeitsCostume: values?.RJMeeitsCostume || "",
    //   Language: values?.Language || "",
    //   // Budget: values?.Budget || "",
    //   // Details: values?.Details || "",
    // }
    const generateEventTable = (events) => {
      if (!Array.isArray(events) || events.length === 0) {
        return `
                    <table style="width: 100%; border-collapse: collapse; margin-top: 10px; font-size: 12px;">
                      <thead>
                        <tr>
                          <th style="border: 1px solid #d3d3d3; padding: 6px; background: #f2f2f2;">Event Type</th>
                          <th style="border: 1px solid #d3d3d3; padding: 6px; background: #f2f2f2;">Event Date</th>
                          <th style="border: 1px solid #d3d3d3; padding: 6px; background: #f2f2f2;">Reporting Time</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td colspan="3" style="border: 1px solid #d3d3d3; padding: 6px; text-align: center;">No Event Data Available</td>
                        </tr>
                      </tbody>
                    </table>`;
      }

      return `
                  <table style="width: 100%; border-collapse: collapse; margin-top: 10px; font-size: 12px;">
                    <thead>
                      <tr>
                        <th style="border: 1px solid #d3d3d3; padding: 6px; background: #f2f2f2;">Event Type</th>
                        <th style="border: 1px solid #d3d3d3; padding: 6px; background: #f2f2f2;">Event Date</th>
                        <th style="border: 1px solid #d3d3d3; padding: 6px; background: #f2f2f2;">Reporting Time</th>
                      </tr>
                    </thead>
                    <tbody>
                      ${events
          .map(
            (event) => `
                          <tr>
                            <td style="border: 1px solid #d3d3d3; padding: 6px;">${event?.EventType || "N/A"}</td>
                            <td style="border: 1px solid #d3d3d3; padding: 6px;">${event?.EventDate ? dayjs(event.EventDate).format("DD/MM/YYYY") : "N/A"
              }</td>
                            <td style="border: 1px solid #d3d3d3; padding: 6px;">${event?.EventStartTime || "N/A"
              }</td>
                          </tr>`
          )
          .join("")}
                    </tbody>
                  </table>`;
    };


    const safeTableData = Array.isArray(formTableData) ? formTableData : [];

    const formData = {
      CompanyFamily: values?.CompanyFamily || "",
      Contact: values?.Contact || "",
      Address: values?.Address || "",
      EmailID: values?.EmailID || "",
      EventVenue: values?.EventVenue || "",
      Requirement: values?.Requirement || "",
      EventTableRows: generateEventTable(safeTableData), // Pass HTML as a string
      RJMeeitsCostume: values.RJMeeitsCostume || "",
      Language:  values.Language || "",
    };
    setLoader(true)
    emailjs
      .send(
        EMAILJS_SERVICE_ID, // Replace with your EmailJS Service ID
        EMAILJS_Template_ID, // Replace with your EmailJS Template ID
        formData,
        EMAILJS_USERID // Replace with your EmailJS User ID
      )
      .then(
        (response) => {
          setLoader(false)
          //   console.log("SUCCESS!", response.status, response.text);
          notifySuccess("Success! Thank You");
          formik.resetForm()
          setFormTableData([
            {
              EventType: "",
              EventDate: "",
              EventStartTime: ""
            }
          ])
        },
        (err) => {
          setLoader(false)
          notifyError("Something Went Wrong");
          //   console.error("FAILED...", err);
        }
      );
  };
  const handleTimeSelect = () => {
    // Save the selected time
    setOpen(false); // Close the time picker immediately after selecting a time
    isSelecting.current = true;  // Mark that a selection occurred
  };
  const handleSubmitWithToast = async (e) => {
    e.preventDefault();
    const emailIDRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    // Trigger validation manually
    await formik.validateForm();
    toast.dismiss();
    // const firstError = Object.keys(formik.errors)[0];
    // console.log(firstError,"firstErrorfirstErrorfirstErrorfirstError")
    if (!formik.values.CompanyFamily) {
      notifyError("Family/Company Name is required")
      return
    } if (!formik.values.Contact) {
      notifyError("Contact is required")
      return
    }
    if (formik.values.Contact.length != 10) {
      notifyError("Contact must be 10 digits")
      return
    }
    if (!formik.values.EmailID) {
      notifyError("Email ID is required")
      return
    }
    if (!emailIDRegex.test(formik.values.EmailID)) {
      notifyError("Invalid email address")
      return
    }

    if (!formik.values.Address) {
      notifyError("Home / Office Address is required")
      return
    }


    if (!formik.values.EventVenue) {
      notifyError("Event Venue Address is required")
      return
    }
    if (validateFormTableData()) {
      notifyError("All fields between Event Type, Event Date, and Reporting Time are required.")
      return
    }
    // if (!formik.values.EventType) {
    //   notifyError("Event Type is required")
    //   return
    // }
    // if (!formik.values.EventDate) {
    //   notifyError("Event Date is required")
    //   return
    // }
    // if (!formik.values.EventStartTime) {
    //   notifyError("Reporting Time Is required")
    //   return
    // }
    // if (!formik.values.Requirement) {
    //   notifyError("Specific Requirement is required")
    //   return
    // }
    // if (!formik.values.EventSession) {
    //   notifyError("Event Session is required")
    //   return
    // }
    // if (!formik.values.Budget) {
    //   notifyError("Budget is required")
    //   return
    // }

    // if (!formik.values.Details) {
    //     notifyError("Details is required")
    //     return
    // }
    if (!formik.values.CheckButton) {
      notifyError("Please agree to the terms & condition.")
      return
    }

    formik.handleSubmit();
  }
  const onChange = (date, index) => {
    let temptFormTableData = [...formTableData]
    temptFormTableData.splice(index, 1, {
      ...formTableData[index],
      EventDate: date
    })
    // if(checkIsRowFillStatus(temptFormTableData[index])){
    //     temptFormTableData.push({
    //         EventType: "",
    //         EventDate: "",
    //         EventStartTime: ""
    //     })
    //  }
    setFormTableData(temptFormTableData)

    // console.log(date,"datedate")
    // formik.setFieldValue("EventDate", date)

  };


  const onTimeChange = (time, index) => {
    let temptFormTableData = [...formTableData]
    temptFormTableData.splice(index, 1, {
      ...formTableData[index],
      EventStartTime: time
    })
    // if(checkIsRowFillStatus(temptFormTableData[index])){
    //     temptFormTableData.push({
    //         EventType: "",
    //         EventDate: "",
    //         EventStartTime: ""
    //     })
    //  }
    setFormTableData(temptFormTableData)
    // formik.setFieldValue("EventStartTime", time)

  }
  const handleEventTypeChange = (e, index) => {
    let temptFormTableData = [...formTableData]
    temptFormTableData.splice(index, 1, {
      ...formTableData[index],
      EventType: e.target.value
    })
    //  if(checkIsRowFillStatus(temptFormTableData[index])){
    //     temptFormTableData.push({
    //         EventType: "",
    //         EventDate: "",
    //         EventStartTime: ""
    //     })
    //  }
    setFormTableData(temptFormTableData)

  }
  const disablePastDates = (current) => {
    // Disable dates before today
    return current && current < dayjs().startOf("day");
  };
  const handleOpenChange = (paramOpen) => {
    if (isSelecting.current) {
      // If a time was selected, prevent reopening
      isSelecting.current = false;
      return;
    }

    // Allow opening if the user clicks the picker
    setOpen(paramOpen);
  };
  const validateFormTableData = () => {
    const temptData = [...formTableData]
    let flag = false
    temptData.map((element, index) => {
      if (!element.EventDate || !element.EventStartTime || !element.EventType) {
        flag = true
        return
      }
    })
    return flag
  }
  const handleDeleteNewTableRow = (index) => {

    const tempTableData = [...formTableData]
    if (tempTableData.length == 1) {
      setFormTableData([{
        EventType: "",
        EventDate: "",
        EventStartTime: ""
      }])
      return
    }
    tempTableData.splice(index, 1)
    setFormTableData(tempTableData)
  }
  const handleAddNewTableRow = () => {
    toast.dismiss()
    if (validateFormTableData()) {
      return notifyError("All fields between Event Type, Event Date, and Reporting Time are required.")
    }
    const tempTableData = {
      EventType: "",
      EventDate: "",
      EventStartTime: ""
    }
    setFormTableData((prev) => [...prev, tempTableData])
  }


  return (
    <>
      {loader && <Loader />}
      <ToastContainer />
      <section className="elementor-section elementor-top-section elementor-element elementor-element-34036ee elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" >
        <div className="elementor-container elementor-column-gap-extended">

          <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-d543b7d sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"  >
            <div className="elementor-widget-wrap elementor-element-populated">
              <div className="elementor-element elementor-element-efb500d sc_fly_static elementor-widget elementor-widget-trx_sc_contact_form_7 trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"   >
                <div className="elementor-widget-container">
                  <div className="wpcf7 js alert_inited" id="wpcf7-f5-p1440-o1" lang="en-US" dir="ltr">
                    <div className="screen-reader-response"><p role="status" /> <ul /></div>
                    <form className="wpcf7-form init" noValidate="novalidate" autoComplete='off'>
                      <div >
                        <input type="hidden" name="_wpcf7" defaultValue={5} />
                        <input type="hidden" name="_wpcf7_version" defaultValue="5.9.5" />
                        <input type="hidden" name="_wpcf7_locale" defaultValue="en_US" />
                        <input type="hidden" name="_wpcf7_unit_tag" defaultValue="wpcf7-f5-p1440-o1" />
                        <input type="hidden" name="_wpcf7_container_post" defaultValue={1440} />
                        <input type="hidden" name="_wpcf7_posted_data_hash" defaultValue />
                      </div>
                      <div className="form-style-1">
                        <div className="columns_wrap">
                          <div className="column-1_2">
                            <p style={{ display: "flex" }}>*<span className="style-line icon-name"><span className="wpcf7-form-control-wrap" ><input maxLength={100} id='CompanyFamily' onChange={formik.handleChange} value={formik.values.CompanyFamily} className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required fill_inited" placeholder="Family/Company Name" type="text" name="CompanyFamily" /><span className="line" /></span></span>
                            </p>
                          </div>
                          <div className="column-1_2">
                            <p style={{ display: "flex" }}>*<span className="style-line icon-phone"><span className="wpcf7-form-control-wrap" ><input maxLength={10} id='Contact' name="Contact" onChange={formik.handleChange} onKeyDown={handleKeyDownNumber} type='tel' value={formik.values.Contact}
                              onPaste={handlePaste} className="wpcf7-form-control wpcf7-tel wpcf7-validates-as-required wpcf7-text wpcf7-validates-as-tel fill_inited" placeholder="Contact Person Number" /><span className="line" /></span></span>
                            </p>
                          </div>
                        </div>
                        <div className="columns_wrap">
                          <div className="column-1_2">
                            <p style={{ display: "flex" }}>*<span className="style-line icon-email"><span className="wpcf7-form-control-wrap" ><input maxLength={40} id='EmailID' onChange={formik.handleChange} value={formik.values.EmailID} className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required fill_inited" placeholder="Email ID" type="text" name="EmailID" /><span className="line" /></span></span>
                            </p>
                          </div>

                          <div className="column-1_2">
                            <p style={{ display: "flex" }}>*<span className="style-line icon-address"><span className="wpcf7-form-control-wrap" ><textarea id='Address' name="Address" value={formik.values.Address} onChange={formik.handleChange} maxLength={1000} cols={40} rows={10} className="wpcf7-form-control wpcf7-textarea wpcf7-validates-as-required fill_inited" placeholder="Home / Office Address" defaultValue={""} /><span className="line" /></span></span>
                            </p>
                          </div>


                          {/* date pending */}

                          {/* date pending */}
                        </div>
                        {formTableData?.map((element, index) => {
                          return <div className="columns_wrap  ">
                            <div className="column-1_4  ">
                              <p style={{ display: "flex" }}>*<span className="style-line icon-new-creative-1-1"><span className="wpcf7-form-control-wrap" ><input maxLength={100} id='EventType'
                                onChange={(e) => handleEventTypeChange(e, index)} value={formTableData[index].EventType} className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required fill_inited" placeholder="Event Type"
                                type="text" name="EventType" /><span className="line" /></span></span>
                              </p>
                            </div>
                            <div className="column-1_4">
                              <p style={{ display: "flex" }}>*<span className="style-line icon-calendar"><span  >
                                <div className="col-md-12 col-lg-12 formInputMargin" >
                                  <Space direction="vertical">
                                    <div className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required fill_inited" >
                                      <DatePicker disabledDate={disablePastDates} value={formTableData[index].EventDate} onKeyDown={(e) => e.preventDefault()} format={"DD-MM-YYYY"} placeholder="DD-MM-YYYY" onChange={(e) => onChange(e, index)} />
                                    </div>
                                  </Space>
                                </div>
                                <span className="line" /></span></span>
                              </p>
                            </div>
                            <div className="column-1_4">
                              <p style={{ display: "flex" }}>*<span className="style-line icon-timer"><span className="wpcf7-form-control-wrap" >
                                <div className="col-md-3 col-lg-3 formInputMargin" >
                                  {/* <Space direction="vertical">
                                                     <div className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required fill_inited" >
                                                       <TimePicker  
                                                               
                                                               onOpenChange={handleOpenChange} // Handle opening and closing manually
                                                       open={open} showSecond={false}  
                                                       onSelect={handleTimeSelect}
                                                        minuteStep={60} placeholder={"Reporting Time"} needConfirm={false} value={formik.values.EventStartTime} format="HH:mm" onKeyDown={(e) => e.preventDefault()}  onChange={onTimeChange} />
                                                     </div >
                                                   </Space> */}
                                  <Select
                                    placeholder="Reporting Time"
                                    // value={formik.values.EventStartTime || null}
                                    value={formTableData[index].EventStartTime || null}
                                    style={{ width: 160, backgroundColor: "transparent", color: "white" }}
                                    onChange={(e) => onTimeChange(e, index)}
                                    options={timeData.map(ele => {
                                      return {
                                        value: ele.value,
                                        label: ele.label
                                      }
                                    })}
                                  />
                                </div>
                                {/* <input  id='EventStartTime' onChange={formik.handleChange} value={formik.values.EventStartTime} className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required fill_inited" placeholder="Event Start Time" type="time" name="EventStartTime" /> */}
                                <span className="line" /></span></span>
                              </p>
                            </div>
                            <div className="column-1_4  ">

                              <button onClick={(e) => { e.preventDefault(); handleAddNewTableRow() }} className='me-2' style={{ fontSize: "0.9em", padding: "6px 20px", backgroundColor: "white", color: "black" }} >
                                Add More Event
                              </button>
                              <button onClick={(e) => { e.preventDefault(); handleDeleteNewTableRow(index) }} style={{ fontSize: "0.9em", padding: "6px 20px", color: "black", backgroundColor: "white" }}>
                                Delete
                              </button>
                            </div>
                          </div>
                        })}
                        <div className="columns_wrap">



                          <div className="column-1_2">
                            <p style={{ display: "flex" }}>*<span className="style-line icon-location-light"><span className="wpcf7-form-control-wrap" ><textarea maxLength={100} id='EventVenue' onChange={formik.handleChange} value={formik.values.EventVenue} className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required fill_inited" placeholder="Event Venue Address" type="text" name="EventVenue" /><span className="line" /></span></span>
                            </p>
                          </div>

                          <div className="column-1_2">
                            <p style={{ display: "flex" }}><span className="style-line icon-textarea"><span className="wpcf7-form-control-wrap" ><textarea maxLength={100} id='Requirement' onChange={formik.handleChange} value={formik.values.Requirement} className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required fill_inited" placeholder="Any Specific Requirement" type="text" name="Requirement" /><span className="line" /></span></span>
                            </p>
                          </div>

                          {/* <div className="column-1_2">
                            <p style={{ display: "flex" }}>*<span className="style-line icon-events"><span className="wpcf7-form-control-wrap" ><input maxLength={100} id='EventSession' onChange={formik.handleChange} value={formik.values.EventSession} className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required fill_inited" placeholder="Event Session" type="text" name="EventSession" /><span className="line" /></span></span>
                            </p>
                          </div> */}


                        </div>
                        <div className="columns_wrap">
                          {/* <div className="column-1_2">
                            <p style={{ display: "flex" }}>&nbsp;&nbsp;<span className="style-line icon-new-dress"><span className="wpcf7-form-control-wrap" ><input id='RJMeeitsCostume' onChange={formik.handleChange} value={formik.values.RJMeeitsCostume} className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required fill_inited" placeholder="RJMeeits Costume" type="text" name="RJMeeitsCostume" /><span className="line" /></span></span>
                            </p>
                          </div>
                          <div className="column-1_2">
                            <p style={{ display: "flex" }}>&nbsp;&nbsp;<span className="style-line icon-name"><span className="wpcf7-form-control-wrap" ><input id='Language' onChange={formik.handleChange} value={formik.values.Language} className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required fill_inited" placeholder="Language" type="text" name="Language" /><span className="line" /></span></span>
                            </p>
                          </div> */}

                          <div className="column-1_2">
                            <p style={{ display: "flex" }}>&nbsp;&nbsp;<span>
                              <span className="" >
                                <label className='Radiocss'>RJ Meeits Costume</label>
                                <RadioButtonList className='Radiocss' options={Costumeoptions} name="RJMeeitsCostume" formik={formik} />
                              </span></span>
                            </p>
                          </div>
                          <div className="column-1_2">
                            <p style={{ display: "flex" }}>&nbsp;&nbsp;<span>
                              <span className="" >
                                <label className='Radiocss'>Language</label>
                                <RadioButtonList className='Radiocss' options={Languageoptions} name="Language" formik={formik} />
                              </span></span>
                            </p>
                          </div>


                          {/* <div className="column-1_2">
                            <p style={{ display: "flex" }}>*<span className="style-line icon-money-light"><span className="wpcf7-form-control-wrap" ><input onKeyDown={handleKeyDownNumber}
                              maxLength={10} onPaste={handlePaste} id='Budget' onChange={formik.handleChange} value={formik.values.Budget} className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required fill_inited" placeholder="Budget" type="text" name="Budget" /><span className="line" /></span></span>
                            </p>
                          </div> */}
                        </div>
                        {/*<div className="columns_wrap margin-bottom">
                          
                           <div className="column-1_2">
                            <p><span className="style-line icon-textarea"><span className="wpcf7-form-control-wrap" ><textarea id='Details' name="Details" value={formik.values.Details} onChange={formik.handleChange} maxLength={1000} cols={40} rows={10} className="wpcf7-form-control wpcf7-textarea wpcf7-validates-as-required fill_inited" placeholder="How can we help you? Feel free to get in touch!" defaultValue={""} /><span className="line" /></span></span>
                            </p>
                          </div> 

                        </div>*/}


                        <div class="terms-container">
                          <h5>We will get back to you within 48 hours regarding this inquiry.</h5>
                          <p>
                            By clicking "Submit", you agree to our <a href="#"> Terms of Service </a>
                            and <a href="#">Privacy Policy</a>. Please read them carefully before proceeding.
                          </p>
                          <ul>
                            <li>The date will be confirmed only on receiving 50% of the payment (via cash/UPI/Bank Transfer), the remaining 50% should be done immediately after the completion of the event.</li>
                            <li>The event shall be completed in a maximum of 3 hours including waiting time.</li>
                            <li>RJ Meeit only participate in Continue Active Anchoring Participation.</li>
                            <li>Only 1 meeting will be held for the event discussion</li>
                            <li>Advance Payment once received will not be refundable under any circumstances.</li>
                            <li>Accommodation for 2 people should be at minimum 4 Star + hotel/resort for outstation shows.</li>
                          </ul>
                          <p className='homeMainTitle' style={{ textAlign: "left" }}><span className="wpcf7-submit-style"><span className="submit-style-in" /></span><span className="wpcf7-form-control-wrap" ><span className="wpcf7-form-control wpcf7-acceptance"><span className="wpcf7-list-item"><label>
                            <input name='CheckButton' id='CheckButton' checked={formik.values.CheckButton} value={formik.values.CheckButton} onChange={(e) => formik.setFieldValue("CheckButton", e.target.checked)} type="checkbox" defaultValue={1} className="inited" />
                            <span className="wpcf7-list-item-label">I agree the terms & conditions</span></label></span></span></span>
                          </p>
                        </div>

                        <p className='homeMainTitle'><span className="wpcf7-submit-style"><input onClick={(e) => handleSubmitWithToast(e)} className="wpcf7-form-control wpcf7-submit has-spinner" type="submit" defaultValue="Get in Touch" /><span className="submit-style-in" /></span><span className="wpcf7-spinner" /><span className="wpcf7-form-control-wrap" ><span className="wpcf7-form-control wpcf7-acceptance"><span className="wpcf7-list-item"><label>
                        </label></span></span></span>
                        </p>
                      </div><div className="wpcf7-response-output" />
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default BookEnquiryForm
