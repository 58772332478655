import React from 'react'
import "./index.css"
import emailjs from "emailjs-com";
import { handleKeyDownNumber, handlePaste, notifyError, notifySuccess } from '../../HelperMethod';
import { EMAILJS_SERVICE_ID, EMAILJS_Template_ID, EMAILJS_USERID } from '../../Process';
import * as Yup from "yup";
import { toast } from 'react-toastify';
import { useFormik } from 'formik'
import { DatePicker, Space, TimePicker } from 'antd';
import dayjs from 'dayjs';
import { ToastContainer } from 'react-toastify';
import Loader from '../Loader';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import MapComponent from '../MapComponent';
import { color } from 'framer-motion';

const ContactUs = () => {
  const latitude = 21.147045006755413
  const longitude = 72.76639171586062
  // const Address = "RJ MEEIT EVENTS 804 SNS ARISTA, OPP. HAPPY RESIDENCY, BH. PRIME SHOPPERS, VESU SURAT 395007"
  const Address = "804 SNS ARISTA, OPP. HAPPY RESIDENCY, BH. PRIME SHOPPERS, VESU SURAT 395007"
  const googleMapsUrl = `https://www.google.com/maps?q=${latitude},${longitude}`;
  // const GOOGLE_MAP_API_KEY = "AIzaSyA8Io_sD3d5uTz6ZTj4FKae_I7mdBVjX9o&v=3.exp&sensor=false&callback=initMap"

  return (
    <div className="page_content_wrap "  >
      <div className="content_wrap_fullscreen aboutPadding">
        <div className="content">
          <a id="content_skip_link_anchor" className="eventicity_skip_link_anchor" href="#" />
          <article id="post-1440" className="post_item_single post_type_page post-1440 page type-page status-publish hentry">
            <div className="post_content entry-content">
              <div className="elementor elementor-1440">
                <section className="elementor-section elementor-top-section elementor-element elementor-element-b98c6ba elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" >
                  <div className="elementor-container elementor-column-gap-extended">
                    <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-7c064a7 sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" >
                      <div className="elementor-widget-wrap elementor-element-populated">
                        <div className="elementor-element elementor-element-fb670bd sc_fly_static elementor-widget elementor-widget-spacer trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" >
                          <div className="elementor-widget-container">
                            <div className="elementor-spacer">
                              <div className="elementor-spacer-inner" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section className="elementor-section elementor-top-section elementor-element elementor-element-34036ee elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" >
                  <div className="elementor-container elementor-column-gap-extended">
                    <div className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-d36a6aa sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" >
                      <div className="elementor-widget-wrap elementor-element-populated">
                        <div className="elementor-element elementor-element-017f731 sc_fly_static elementor-widget elementor-widget-trx_sc_title trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" >
                          <div className="elementor-widget-container">
                            <div className="contact_details_main sc_title sc_title_default"><h4 style={{ marginBottom: "30px" }} className="sc_item_title sc_title_title sc_item_title_style_default sc_item_title_tag"><span className="sc_item_title_text_KeepINTouch" ></span></h4></div></div>
                        </div>
                        <div className="elementor-element elementor-element-1593bdc sc_fly_static elementor-widget elementor-widget-spacer trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" >
                          <div className="elementor-widget-container">
                            <div className="elementor-spacer">
                              <div className="elementor-spacer-inner" />
                            </div>
                          </div>
                        </div>
                        <div className="elementor-element elementor-element-4a037f5 sc_fly_static elementor-widget elementor-widget-trx_sc_icons trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" >
                          <div className="elementor-widget-container">
                            <div className="sc_icons color_style_link2 sc_icons_simple sc_icons_size_small sc_align_none">

                              <div className=" with_more">
                                <strong style={{ color: "white", fontSize: "1.2em", marginLeft: "22px" }}>RJ MEEIT EVENTS</strong>
                                <div className='sc_icons_item'>
                                  <span style={{ marginRight: "5px" }} className=" icon-address " />
                                  <div className="sc_icons_item_details"><div className="sc_icons_item_description">
                                    <span>
                                      ADDRESS : 804 SNS ARISTA, OPP. HAPPY RESIDENCY, BH. PRIME SHOPPERS,
                                      VESU SURAT 395007</span>
                                    {/* <span>Berlin, De 81566</span> */}
                                  </div></div>
                                </div>
                              </div>

                              <div className="sc_icons_item marginbt">
                                <span style={{ marginRight: "5px" }} className="icon-email " />
                                <div className="sc_icons_item_details">
                                  <div className="sc_icons_item_description">
                                    <span><a href="#" className="underline_hover">INFO@RJMEEIT.COM</a></span>
                                  </div>
                                </div>
                              </div>

                              <div className="sc_iconitem-self sc_icons_item sc_icons_item_linked with_more">
                                <div className="sc_icons_item_details">
                                  <a href="#" className="sc_icons_item_more_link">
                                    <span className="link_text">
                                      <NavLink to={"tel:+91 9687609704"}>
                                        <span style={{ marginRight: "8px" }} className=" icon-phone " />+91 9687609704
                                      </NavLink>
                                    </span>
                                    <br />
                                    <span className="link_text">
                                      <NavLink to={"tel:+91 9998683062"}>
                                        <span style={{ marginRight: "8px" }} className=" icon-phone " />+91 9998683062
                                      </NavLink>
                                    </span>
                                    <span className="link_icon" />
                                  </a><a href="#" className="sc_icons_item_link">
                                  </a></div></div>
                              <div className='sc_iconitem-self sc_icons_item sc_icons_item_linked with_more'>
                                <div className="sc_socials sc_socials_default sc_align_none">
                                  <div className="sc_socials sc_socials_default sc_align_none">
                                    <div className="socials_wrap sc_item_content">
                                    {/* <span className="fas fa-sign-out-alt" style={{ color: 'white', marginRight: '10px', animation: 'zoomInOut 2s infinite ease-in-out' }} /> */}

                                      <NavLink target="_blank" to="https://www.facebook.com/rjmeeitmishrra/" className="social_item social_item_style_icons sc_icon_type_icons social_item_type_icons">
                                        <span className="social_icon social_icon_facebook-1">
                                          <span className="icon-facebook-1" />
                                        </span>
                                      </NavLink>

                                      <NavLink target="_blank" to="https://www.youtube.com/@RJMeeitMishrra" className="social_item social_item_style_icons sc_icon_type_icons social_item_type_icons">
                                        <span className="social_icon social_icon_youtube">
                                          <span className="icon-youtube" />
                                        </span>
                                      </NavLink>
                                      <NavLink target="_blank" to="https://www.instagram.com/rjmeeit_mishrra/" className="social_item social_item_style_icons sc_icon_type_icons social_item_type_icons">
                                        <span className="social_icon social_icon_instagram">
                                          <span className="icon-instagram" />
                                        </span>
                                      </NavLink>
                                      <NavLink target="_blank" to="https://www.google.com/search?q=rj+meeit" className="social_item social_item_style_icons sc_icon_type_icons social_item_type_icons">
                                        <span className="social_icon social_icon_google">
                                          <span className="icon-google" />
                                        </span>
                                      </NavLink>
                                      <NavLink target="_blank" to="https://wa.me/9687609704?text=Hello%20there!" className="social_item social_item_style_icons sc_icon_type_icons social_item_type_icons">
                                        <span className="social_icon social_icon_whatsapp">
                                          <span className="icon-whatsapp" />
                                        </span>
                                      </NavLink><br />                                     
                                  
                                      {/* <span  style={{color: 'white', display: 'inline-block',animation: 'zoomInOut 3s infinite ease-in-out' }} >
                                        Click Here
                                      </span> */}
                                    </div>
                                  </div></div>
                              </div>




                            </div>{/* /.sc_icons */}		</div>
                        </div>

                        <div className="elementor-element elementor-element-4282db6 sc_height_medium sc_layouts_hide_on_wide sc_layouts_hide_on_desktop sc_layouts_hide_on_notebook sc_layouts_hide_on_tablet sc_fly_static elementor-widget elementor-widget-spacer trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" >
                          <div className="elementor-widget-container">
                            <div className="elementor-spacer">
                              <div className="elementor-spacer-inner" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="elementor-column elementor-col-66 elementor-top-column elementor-element elementor-element-d543b7d sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"  >
                      <div className="elementor-widget-wrap elementor-element-populated">
                        <div className="elementor-element elementor-element-efb500d sc_fly_static elementor-widget elementor-widget-trx_sc_contact_form_7 trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"   >
                          <div className="elementor-widget-container">
                            <div className="wpcf7 js alert_inited" id="wpcf7-f5-p1440-o1" lang="en-US" dir="ltr">
                              <div className="screen-reader-response"><p role="status" /> <ul /></div>

                              <div style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "20px" }} >
                                <MapComponent className="mapwidth" width={'100%'} height={"500px"} latitude={parseFloat(latitude)} longitude={parseFloat(longitude)} Address={Address} />
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                <section className="elementor-section elementor-top-section elementor-element elementor-element-6e610104 elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" >
                  <div className="elementor-container elementor-column-gap-extended">
                    <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-e311442 sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" >
                      <div className="elementor-widget-wrap elementor-element-populated">
                        <div className="elementor-element elementor-element-7a9dbbc1 sc_height_huge sc_fly_static elementor-widget elementor-widget-spacer trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" >
                          <div className="elementor-widget-container">
                            <div className="elementor-spacer">
                              <div className="elementor-spacer-inner" />
                            </div>
                          </div>
                        </div>
                        <div className="elementor-element elementor-element-3a25e00 sc_fly_static elementor-widget elementor-widget-spacer trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" >
                          <div className="elementor-widget-container">
                            <div className="elementor-spacer">
                              <div className="elementor-spacer-inner" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>{/* .entry-content */}
          </article>
        </div >
      </div >
    </div >
  )
}

export default ContactUs
