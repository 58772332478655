import React, { useState } from "react";
import "./index.css";

const VideoModal = ({ videoUrl, isOpen, setIsOpen }) => {



  const closeModal = () => setIsOpen(false);
  let videoUrlConst = "https://eventicity.themerex.net/wp-content/uploads/2024/02/pexels-marian-croitoru-20203971.mp4"
  return (

    <>
      {isOpen && (
        <div className="video-modal-overlay" onClick={closeModal}>
          <div
            className="video-modal-content"
            onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the modal
          >
            {/* <button className="close-button" onClick={closeModal}>
           &times;
         </button> */}
            <div className="video-container">
              <video
                src={videoUrlConst}
                controls
                autoPlay
                className="video-player"
              ></video>
            </div>
            <div className="modal-footer">
              <button className="cta-button" onClick={closeModal}>
                Close Video
              </button>
            </div>
          </div>
        </div>
      )}
    </>

  );
};

export default VideoModal;
