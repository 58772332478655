import React from 'react'
import "./index.css"
import MindfullAndMature from './MindfullAndMature'
import Energetic from './Energetic'
import Energetic2 from './Energetic2'
import Entertaining from './Entertaining'
import Intelligent from './Intelligent'
import TimePunctual from './TimePunctual'
import SectionSpacer from '../SectionSpacer'


const AboutUs = () => {
  return (
    <div className="page_content_wrap">
      <div className="content_wrap_fullscreen aboutPadding ">
        <div className="content">
          <a id="content_skip_link_anchor" className="eventicity_skip_link_anchor" href="#" />
          <article id="post-3738" className="post_item_single post_type_page post-3738 page type-page status-publish hentry">
            <div className="post_content entry-content">
              <div className="elementor elementor-3738">
                <section className="elementor-section elementor-top-section elementor-element elementor-element-01ef9cb elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"  >
                  <div className="elementor-container elementor-column-gap-extended">
                    <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-16929a4 sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"  >
                      <div className="elementor-widget-wrap elementor-element-populated">
                        <div className="elementor-element elementor-element-d9d8aea sc_fly_static elementor-widget elementor-widget-spacer trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"   >
                          <div className="elementor-widget-container">
                            <div className="elementor-spacer">
                              <div className="elementor-spacer-inner" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section className="elementor-section elementor-top-section elementor-element elementor-element-66395ac elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"  >
                  <div className=" elementor-container elementor-column-gap-extended">
                    <div className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-1820f11c sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"  >
                      <div className="elementor-widget-wrap elementor-element-populated">
                        <div style={{ marginBottom: "25px" }} className="elementor-element elementor-element-8646708 sc_fly_static elementor-widget elementor-widget-trx_sc_title trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited">

                          <div className="panelhead elementor-widget-container">
                            <div className="sc_title sc_title_default"><h1 className="sc_item_title sc_title_title sc_item_title_style_default sc_item_title_tag"><span style={{ lineHeight: "normal" }} className="sc_item_title_texttitle">“ बन ठन कर आऊंगा,<br />
                              &nbsp;&nbsp;&nbsp;&nbsp;अपना बनाकर जाऊंगा ! ”</span></h1></div></div>

                          <div className="mobilehead elementor-widget-container">
                            <div className="sc_title sc_title_default"><h1 className="sc_item_title sc_title_title sc_item_title_style_default sc_item_title_tag"><span style={{ lineHeight: "normal" }} className="sc_item_title_text mobileheadtexttitle">“ बन ठन कर आऊंगा, &nbsp;अपना बनाकर जाऊंगा ! ”</span></h1></div>		</div>

                        </div>
                        {/* <div className="elementor-element elementor-element-0af37fb sc_fly_static elementor-widget elementor-widget-spacer trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"   >
                          <div className="elementor-widget-container">
                            <div className="elementor-spacer">
                              <div className="elementor-spacer-inner" />
                            </div>
                          </div>
                        </div> */}
                        <div className="elementor-element elementor-element-8dd86d3 no-title sc_fly_static elementor-widget elementor-widget-image trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"   >
                          <div className="aboutImgDivSelf elementor-widget-container aboutImgDiv" >
                            <img src={require("../../Assets/About2.jpg")} title="img-47-copyright" alt="img-47-copyright" loading="lazy" className='pointerevent' />													</div>
                        </div>
                      </div>
                    </div>
                    <div className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-9320c63 sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"  >
                      <div className="elementor-widget-wrap elementor-element-populated">
                        <div className="elementor-element elementor-element-47bb7597 no-title sc_fly_static elementor-widget elementor-widget-image trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"   >
                          <div className="elementor-widget-container">
                            <img src={require("../../Assets/About1.jpg")} title="img-46-copyright" alt="img-46-copyright" loading="lazy" className='pointerevent'/>													</div>
                        </div>
                        {/* <div className="elementor-element elementor-element-f5e1a0 sc_fly_static elementor-widget elementor-widget-spacer trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"   >
                          <div className="elementor-widget-container">
                            <div className="elementor-spacer">
                              <div className="elementor-spacer-inner" />
                            </div>
                          </div>
                        </div> */}
                        <div className="elementor-element elementor-element-3cc6356f sc_fly_static elementor-widget elementor-widget-text-editor trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"  >
                          <div className="elementor-widget-container">
                            <p style={{ textAlign: "justify" }}>RJ Meeit brings 15+ years of media & emceeing experience to your events. With his sound personality, he can convince the toughest crowd to sing, dance & even sit to his tune in a matter of seconds. A punctual, organized & hardworking individual with an amazing stage person that can only be outshined by his time-tested rapport with the audience & organizers. The achievement of organizing & hosting hundreds of successful mega-events rests as a shining jewel to his crown. Yet, you won't find a single ounce of pride in his conduct if you see him in action, making your events special with utmostrespect, authenticity & enthusiasm.</p>						</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <SectionSpacer />
                <MindfullAndMature />
                <SectionSpacer />
                <Energetic2 />
                <SectionSpacer />
                <Entertaining />
                <SectionSpacer />
                <Intelligent />
                <SectionSpacer />
                <TimePunctual />
              </div>
            </div>{/* .entry-content */}
          </article>
        </div>
      </div>
    </div>
  )
}

export default AboutUs
