import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { changeMobileNavStatus } from '../../Redux/Slices'
import { NavLink, useNavigate } from 'react-router-dom'

const MobileNav = () => {
    const { mobileNavOpen } = useSelector(state => state.MainSlice)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const handleMobileNavOpen = () => {
        dispatch(changeMobileNavStatus(false))
    }
    const handleMobileNavLinkClick = (path) => {
        dispatch(changeMobileNavStatus(false))
        navigate(`/RJmeeit${path}`)
    }
    return (
        <div>
            <div className="menu_mobile_overlay_self menu_mobile_overlay scheme_dark inited_click" />
            <div className={`menu_mobile menu_mobile_fullscreen scheme_dark inited_arrows inited_click ${mobileNavOpen ? "opened" : ""}`}>
                {/* opened */}
                <div className="menu_mobile_inner with_widgets">
                    <div className="menu_mobile_header_wrap">
                        <NavLink to={"/RJmeeit/Home"} className="sc_layouts_logo" href="#">
                            <h2 className="sc_layouts_title_caption">RJ Meeit</h2>
                            {/* <img src={require("../../Assets/logo-inverse-2x.jpg")} alt="RJmeeit" width={177} height={51} /> */}
                        </NavLink>
                        <a className="menu_mobile_close menu_button_close inited_click" tabIndex={0}><span className="menu_button_close_text">Close</span><span onClick={() => handleMobileNavOpen()} className="menu_button_close_icon" /></a>
                    </div>
                    <div className="menu_mobile_content_wrap content_wrap">
                        <div className="menu_mobile_content_wrap_inner"><nav className="menu_mobile_nav_area" itemScope="itemscope" itemType="https://schema.org/SiteNavigationElement"><ul id="mobile-menu_mobile" className="menu_mobile_nav prepared"><li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-18077"><a href="#"><span onClick={() => handleMobileNavLinkClick("/Home")}>Home</span><span className="open_child_menu" /></a>
                        </li>
                            <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-52451"><a href="#"><span onClick={() => handleMobileNavLinkClick("/About")}>About Us</span><span className="open_child_menu" /></a>
                            </li>
                            <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-18100"><a href="#"><span onClick={() => handleMobileNavLinkClick("/Portfolio")}>Achievment</span><span className="open_child_menu" /></a>
                            </li>
                            {/* <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-18100"><a href="#"><span onClick={() => handleMobileNavLinkClick("/Achievment")}>Achievment</span><span className="open_child_menu" /></a>
                            </li> */}
                            <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-17952 current-menu-ancestor current-menu-parent"><a href="#"><span onClick={() => handleMobileNavLinkClick("/Team")}>Team</span><span className="open_child_menu" /></a>
                            </li>

                            <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-17952 current-menu-ancestor current-menu-parent"><a href="#"><span onClick={() => handleMobileNavLinkClick("/BookingInquiry")}>Booking Inquiry</span><span className="open_child_menu" /></a>
                            </li>

                            <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-18098"><a href="#"><span onClick={() => handleMobileNavLinkClick("/Contact")}>Contact Us</span><span className="open_child_menu" /></a>
                            </li>
                        </ul></nav><div className="socials_mobile">
                                <NavLink target="_blank" to={"https://www.facebook.com/rjmeeitmishrra/"} className="social_item social_item_style_icons sc_icon_type_icons social_item_type_icons"><span className="social_icon social_icon_facebook-1" ><span className="icon-facebook-1" /></span></NavLink>
                                <NavLink target="_blank" to={"https://www.youtube.com/@RJMeeitMishrra"} className="social_item social_item_style_icons sc_icon_type_icons social_item_type_icons"><span className="social_icon social_icon_youtube" ><span className="icon-youtube" /></span></NavLink>
                                <NavLink target="_blank" to={"https://www.instagram.com/rjmeeit_mishrra/"} className="social_item social_item_style_icons sc_icon_type_icons social_item_type_icons"><span className="social_icon social_icon_instagram" ><span className="icon-instagram" /></span></NavLink>
                                <NavLink target="_blank" to={"https://www.google.com/search?q=rj+meeit&sca_esv=81eb3f1053a10409&ei=TQltZ9ryH9vP1e8PrbjTUA&ved=0ahUKEwiazcyZ-MSKAxXbZ_UHHS3cFAoQ4dUDCBA&uact=5&oq=rj+meeit&gs_lp=Egxnd3Mtd2l6LXNlcnAiCHJqIG1lZWl0MhEQLhgWGMcBGAoYHhiOBRivATILEAAYgAQYhgMYigUyCxAAGIAEGIYDGIoFMgsQABiABBiGAxiKBTILEAAYgAQYhgMYigUyCBAAGIAEGKIEMgUQABjvBTIFEAAY7wUyBRAAGO8FMiAQLhgWGMcBGAoYHhiOBRivARiXBRjcBBjeBBjgBNgBAUj-BlDfA1j-BHABeACQAQCYAb4BoAHuA6oBAzAuM7gBA8gBAPgBAZgCAqACyAHCAgoQABiwAxjWBBhHmAMAiAYBkAYIugYGCAEQARgUkgcDMS4xoAfwFQ&sclient=gws-wiz-serp#lrd=0x3be04dc9a6cbbea9:0x5a97eb2cd7896609,1,,,,"} className="social_item social_item_style_icons sc_icon_type_icons social_item_type_icons"><span className="social_icon social_icon_google" ><span className="icon-google" /></span></NavLink>
                                <NavLink target="_blank" to={"https://wa.me/9687609704?text=Hello%20there!"} className="social_item social_item_style_icons sc_icon_type_icons social_item_type_icons"><span className="social_icon social_icon_whatsapp" ><span className="icon-whatsapp" /></span></NavLink>

                            </div>          </div>
                    </div><div className="menu_mobile_widgets_area">				<div className="widgets_additional_menu_mobile_fullscreen widgets_additional_menu_mobile_fullscreen_wrap widget_area">
                        <div className="widgets_additional_menu_mobile_fullscreen_inner widgets_additional_menu_mobile_fullscreen_inner widget_area_inner">
                            <aside className="widget_text widget widget_custom_html"><div className="textwidget custom-html-widget"><div className="extra_item">
                                {/* <h6>Have a Project?</h6>
                                <a href="#" className="underline_anim">info@website.com</a> */}
                            </div>
                                <div className="extra_item">
                                    {/* <h6>Where to Find Us?</h6>
                                    <a href="#">Look Here</a> */}
                                </div>
                                <div className="extra_item">
                                    {/* <h6>Ready to Shop?</h6>
                                    <a href="#">Visit the Store</a> */}
                                </div></div></aside>					</div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MobileNav
