import React from 'react'

const HomeService = () => {
    return (
        <section id="homeServices" className="elementor-section elementor-top-section elementor-element elementor-element-214767b elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" >
            <div className="elementor-container elementor-column-gap-extended">
                <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-86e04e6 sc_layouts_column_align_center sc_layouts_column sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" >
                    <div className="elementor-widget-wrap elementor-element-populated">
                        <div className="elementor-element elementor-element-9b1ba72 sc_height_huge sc_fly_static elementor-widget elementor-widget-spacer trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" >
                            <div className="elementor-widget-container">
                                <div className="elementor-spacer">
                                    <div className="elementor-spacer-inner" />
                                </div>
                            </div>
                        </div>
                        {/* <div className="elementor-element elementor-element-a61580b sc_fly_static elementor-widget elementor-widget-spacer trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" >
                            <div className="elementor-widget-container">
                                <div className="elementor-spacer">
                                    <div className="elementor-spacer-inner" />
                                </div>
                            </div>
                        </div> */}
                        <div className="elementor-element elementor-element-c2f218f animation_type_block sc_fly_static elementor-widget elementor-widget-trx_sc_title trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited animated eventicity-fadeinup" >
                            <div className="elementor-widget-container">
                                <div className="sc_title sc_title_default homeMainTitle"><span className="sc_item_subtitle sc_title_subtitle sc_item_subtitle_above sc_item_title_style_default">What we Do</span><h1 className="sc_item_title sc_title_title sc_item_title_style_default sc_item_title_tag"><span className="sc_item_title_text">Services</span></h1></div>		</div>
                        </div>
                        {/* <div className="elementor-element elementor-element-265aa28 sc_height_small sc_fly_static elementor-widget elementor-widget-spacer trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" >
                            <div className="elementor-widget-container">
                                <div className="elementor-spacer">
                                    <div className="elementor-spacer-inner" />
                                </div>
                            </div>
                        </div> */}
                        <div className="elementor-element elementor-element-014898b animation_type_sequental sc_fly_static elementor-widget elementor-widget-trx_sc_icons animated-separate trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" >


                            <div className="elementor-widget-container">
                                <div className="sc_icons sc_icons_accent sc_icons_size_medium sc_align_center"><div className="sc_icons_columns_wrap sc_item_columns trx_addons_columns_wrap columns_padding_bottom columns_in_single_row"><div className="trx_addons_column-1_4 trx_addons_column-1_2-tablet"><div className="sc_icons_item sc_icons_item_linked animated-item animated eventicity-fadeinup" ><div className="sc_icons_icon sc_icon_type_ icon-new-local-music"><span className="sc_icon_type_ icon-new-local-music" /></div><div className="sc_icons_item_details"><h4 className="sc_icons_item_title"><a href="#">
                                    Anchoring                                 </a></h4><div className="sc_icons_item_description text-justify"><span>RJ Meeit, known for a strong presence and excellent communication, has successfully hosted various events, including corporate, wedding, government, social, and public functions.</span></div><a href="#" className="sc_icons_item_link">
                                    </a></div></div></div><div className="trx_addons_column-1_4 trx_addons_column-1_2-tablet"><div className="sc_icons_item sc_icons_item_linked animated-item animated eventicity-fadeinup" ><div className="sc_icons_icon sc_icon_type_ icon-speech"><span className="sc_icons_icon sc_icon_type_ icon-speech" /></div><div className="sc_icons_item_details"><h4 className="sc_icons_item_title"><a href="#">
                                        Voice Over Artist                                  </a></h4><div className="sc_icons_item_description text-justify"><span>
                                            RJ Meeit, with a dynamic voice and expertise in tone, pacing, and character portrayal, specializes in commercials, animations, audiobooks, and corporate narrations.</span></div><a href="#" className="sc_icons_item_link">
                                        </a></div></div></div><div className="trx_addons_column-1_4 trx_addons_column-1_2-tablet"><div className="sc_icons_item sc_icons_item_linked animated-item animated eventicity-fadeinup" ><div className="sc_icons_icon sc_icon_type_ icon-new-speaker"><span className="sc_icon_type_ icon-new-speaker" /></div><div className="sc_icons_item_details"><h4 className="sc_icons_item_title"><a href="#">
                                            Soft Skill Trainer                                  </a></h4><div className="sc_icons_item_description text-justify"><span>RJ Meeit skillfully manages and conducts specialized programs aimed at helping individuals enhance their communication, leadership, and interpersonal skills.</span></div><a href="#" className="sc_icons_item_link">
                                            </a></div></div></div><div className="trx_addons_column-1_4 trx_addons_column-1_2-tablet"><div className="sc_icons_item sc_icons_item_linked animated-item animated eventicity-fadeinup" ><div className="sc_icons_icon sc_icon_type_ icon-sport"><span className="sc_icon_type_ icon-new-shooting" /></div><div className="sc_icons_item_details"><h4 className="sc_icons_item_title"><a href="#">
                                                Game Jockey                                   </a></h4><div className="sc_icons_item_description text-justify"><span>RJ Meeit excels in hosting live game shows, competitions, and interactive events, creating a fun and lively atmosphere for an unforgettable experience.</span></div><a href="#" className="sc_icons_item_link">
                                                </a></div></div></div></div></div>	</div><br></br>
                        </div>

                        {/* <div className="elementor-element elementor-element-9382e65 sc_height_huge sc_fly_static elementor-widget elementor-widget-spacer trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" >
                            <div className="elementor-widget-container">
                                <div className="elementor-spacer">
                                    <div className="elementor-spacer-inner" />
                                </div>
                            </div>
                        </div> */}

                        <div className="elementor-element elementor-element-bed5023 sc_fly_static elementor-widget elementor-widget-spacer trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited">
                            <div className="elementor-widget-container">
                                <div className="elementor-spacer">
                                    <div className="elementor-spacer-inner" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default HomeService
